import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { RouterProvider, createBrowserRouter } from "react-router-dom";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store/index";
import { IndexRouters } from "./router";

import './datatablesDefaults'; // DataTables 기본 설정 가져오기

const router = createBrowserRouter(
  [
    ...IndexRouters,
  ],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
      <App>
        <RouterProvider router={router}></RouterProvider>
      </App>
    </Provider>
  </StrictMode>
);