import { useState, useEffect } from 'react';
import { getSales } from '../../api/sales';

const useFetchSales = (salesId) => {
  const [salesData, setSalesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const sales = await getSales(salesId);
        sales.selectInstructor = {
          value: sales.instructorId,
          label: sales.instructorUser.name,
        };

        setSalesData(sales);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSales();
  }, [salesId]);

  return { salesData, loading, error };
};

export default useFetchSales;
