import { Col, Form, Row } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import RadioButton from "../../../../components/button/RadioButton";
import CheckboxGroup from "../../../../components/form/CheckboxGroup";

const UserSurveyRightForm = ({ formData, handleChange, options, handleCheckboxChange }) => (
  <div className="col-md-6">
    <Card>
      <Card.Body>
        <div className="new-user-info">
          <Form>
            <div className="row">
              <Form.Group className="col-md-12 form-group">
                <Form.Label>신장</Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label>체중</Form.Label>
                <Form.Control
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <RadioButton
                label="최근에 심각한 체중변화가 있으십니까?"
                options={options.radioDefaultValue}
                fieldName="recentWeightChange"
                selectedValue={formData.recentWeightChange}
                handleChange={handleChange}
              />
              <RadioButton
                label="피부가 마사지 및 지압 등에 의해 쉽게 멍이 드시거나 특정 알레르기가 있으십니까?"
                options={options.radioDefaultValue}
                fieldName="skinDisease"
                selectedValue={formData.skinDisease}
                handleChange={handleChange}
              />
              <RadioButton
                label="음주 하십니까?"
                options={options.radioDefaultValue}
                fieldName="drinking"
                selectedValue={formData.drinking}
                handleChange={handleChange}
              />
              <RadioButton
                label="흡연 하십니까?"
                options={options.radioDefaultValue}
                fieldName="smoking"
                selectedValue={formData.smoking}
                handleChange={handleChange}
              />
              <CheckboxGroup
                label="아래의 질환 중 과거에 앓으셨거나, 현재 앓고 계시는 질환이 있으시면 표시해주세요."
                options={options.diseaseDetails}
                fieldName="diseaseDetails"
                selectedOptions={formData.diseaseDetails}
                handleCheckboxChange={handleCheckboxChange}
              />
              <RadioButton
                label="가족의 유전적인 질환이 있으십니까?"
                options={options.radioDefaultValue}
                fieldName="geneticDisease"
                selectedValue={formData.geneticDisease}
                handleChange={handleChange}
              />
              <RadioButton
                label="수술을 받으시거나 입원하신 적이 있으십니까?"
                options={options.radioDefaultValue}
                fieldName="surgicalHistory"
                selectedValue={formData.surgicalHistory}
                handleChange={handleChange}
              />
              <RadioButton
                label="종합검사를 받으신 적이 있으십니까?"
                options={options.radioDefaultValue}
                fieldName="inspectionDetails"
                selectedValue={formData.inspectionDetails}
                handleChange={handleChange}
              />
              <hr />
              <h5 className="mb-4">아래는 여성만 선택해 주십시오 👩🏻</h5>
              <RadioButton
                label="생리 상태"
                options={options.periodState}
                fieldName="periodState"
                selectedValue={formData.periodState}
                handleChange={handleChange}
              />
              <Form.Group className="form-group">
                <Form.Label htmlFor="exampleFormControlSelect1">임신 상태</Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      name="pregnancyMonth"
                      value={formData.pregnancyMonth}
                      onChange={handleChange}
                      placeholder="개월"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="childbirthDate"
                      value={formData.childbirthDate}
                      onChange={handleChange}
                      placeholder="출산예정일"
                    />
                  </Col>
                  <Col>
                  <Form.Control
                      type="text"
                      name="pregnancyMemo"
                      value={formData.pregnancyMemo}
                      onChange={handleChange}
                      placeholder="비고"
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="exampleFormControlSelect1">출산 경력</Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      name="childbirthCount"
                      value={formData.childbirthCount}
                      onChange={handleChange}
                      placeholder="출산 횟수"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="childbirthMemo"
                      value={formData.childbirthCount}
                      onChange={handleChange}
                      placeholder="비고 (자연분만, 제왕절개)"
                    />
                  </Col>
                </Row>
              </Form.Group>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default UserSurveyRightForm;
