import React, { memo, Fragment, useRef, useEffect, useMemo } from "react";

//react-bootstrap
import { Row, Col, Button } from "react-bootstrap";

//components
import Card from "../../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../../components/hooks/useDatatable";

import $ from "jquery";
import ReactDOMServer from "react-dom/server";

import useInstructorLists from "../../../../hooks/useInstructorList";
import useUserMe from "../../../../hooks/useUserMe";
import EditIcon from "../../../../components/icon/EditIcon";
import ListButton from "../../../../components/button/ListButton";
import { instructorMapping } from "../../../../mapping/userMapping";

const InstructorWageList = memo(() => {
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const { instructorList, loading, error } = useInstructorLists(storeId);

  const columns = useMemo(() => [
    { data: "user.name", title: "이름" },
    { data: "user.email", title: "이메일" },
    { data: "user.phone", title: "연락처" },
    { data: "store.name", title: "지점" },
    {
      data: "position",
      title: "직급",
      render: function (data, type, row) {
        return instructorMapping[data] || data;
      },
    },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: function (data, type, row) {
        // userMeData가 null일 수 있으므로 null 체크
        const getInstructor = userMeData.instructor
        if (getInstructor.position !== 'DIRECTOR' && data.id !== getInstructor.id) return ''; 

        return ReactDOMServer.renderToString( // JSX를 문자열로 변환
          <div className="flex align-items-center list-user-action">
            <Fragment>
              <ListButton
                href={`/manage/instructor-wage/${data.id}`}
                tooltipTitle="Edit"
                ariaLabel="Edit"
                className="btn-warning"
              >
                <EditIcon />
              </ListButton>
            </Fragment>
          </div>
        );
      },
    },
  ], [userMeData]); // userMeData가 변경될 때 columns 재생성

  const listtableRef = useRef(null);
  useDataTable({
    tableRef: listtableRef,
    columns: columns,
    data: instructorList,
  });
  
  useEffect(() => {
    if (listtableRef.current && instructorList.length > 0) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
  
      $("#datatable-ecom2").DataTable({
        data: instructorList,
        columns: columns,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [instructorList, columns]);

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">수당 관리</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom2"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default InstructorWageList;