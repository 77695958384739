import { useNavigate, useParams } from "react-router-dom";
import { updateInstructor } from "../../../api/instructor";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import { updateUserPassword, uploadContractImage } from "../../../api/auth";
import useInstructorInfo from "../../../hooks/useInstructorInfo";
import { Link } from "react-router-dom";

import imageCompression from "browser-image-compression";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import CommaFormattedInput from "../../../components/form/CommaFormattedInput";
import useUserMe from "../../../hooks/useUserMe";

const { memo, useState, useEffect, Fragment, useCallback } = require("react");

const InstructorEdit = memo(() => {
  const { instructorId } = useParams();
  const { userMeData, error: userInfoError } = useUserMe();
  const storeData = {
    id: "",
    name: "",
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBackClick = () => {
    navigate("/manage/instructor-list"); // Navigate to the previous page in the history stack
  };

  const [formData, setFormData] = useState({
    id: "",
    userId: "",
    name: "",
    email: "",
    phone: "",
    birth: "",
    address: "",
    position: "",
    pay: 0,
    hiddenPay: 0,
    workType: "",
    status: "",
    contractImage: "",
    contractStartDate: "",
    contractEndDate: "",
    store: storeData,
    createdAt: "",
    updatedAt: "",
  });
  
  // 강사 정보 조회
  const { instructorData, refresh } = useInstructorInfo(instructorId);
  useEffect(() => {
    if (instructorData) {
      setContractView(instructorData.contractImage);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...instructorData,
      }));
    }
  }, [instructorData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = (selectedDates, name) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
      const [startDate, endDate] = formattedDates;
  
      setFormData((prevData) => ({
        ...prevData,
        contractStartDate: startDate,
        contractEndDate: endDate
      }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await updateInstructor(formData);
      setSuccess(true);
      alert('저장되었습니다.');
      window.location.reload();
    } catch (error) {
      alert(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // 비밀번호 변경 
  const [password, setPassword] = useState('');
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value)
  };
  const handlePasswordChangeUpdate = useCallback(async () => {
    const confirmDelete = window.confirm("정말로 변경하시겠습니까?");
    if (confirmDelete) {
      try {
        await updateUserPassword(instructorData?.userId, password);
        alert("변경되었습니다.");
        handleClose();
        setPassword('');
        refresh(); // 데이터 갱신
      } catch (error) {
        alert(error.message);
      }
    }
  }, [refresh, instructorData?.userId, password]);

  // 계약서
  const [contractView, setContractView] = useState(null);
  const handleContractImageUpload = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    setLoading(true);

    try {
      // 이미지 압축
      const compressedFile = await compressImage(files[0]);
      const url = await uploadContractImage(compressedFile, formData.store.id, instructorData?.userId);
      formData.contractImage = url;

      setFormData(formData);
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  };

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">비밀번호 변경</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="col-md-12 form-group">
              <Form.Label htmlFor="fname">신규 비밀번호</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePasswordChangeUpdate}>
            변경
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">강사 상세</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-success"
                  onClick={handleShow}
                  disabled={loading}
                >
                  비밀번호 변경
                </Button>{" "}
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  {loading ? "저장" : "저장"}
                </Button>{" "}
                <Button
                  type="button"
                  variant="btn btn-light"
                  onClick={handleBackClick}
                >
                  뒤로가기
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <div className="col-md-6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">개인 정보</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="new-user-info">
                <Form>
                  <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">이름</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="lname">이메일 (아이디)</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add1">연락처</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add2">생년월일</Form.Label>
                      <Form.Control
                        type="text"
                        name="birth"
                        value={formData.birth}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add2">주소</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">설정</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="new-user-info">
                <Form>
                  <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">고정금액</Form.Label>
                      <CommaFormattedInput
                        name="pay"
                        value={formData.pay}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    {userMeData && userMeData.instructor.position === "DIRECTOR" && (
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">고정금액(2번째)</Form.Label>
                      <CommaFormattedInput
                        name="hiddenPay"
                        value={formData.hiddenPay}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    )}
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="positionSelect">
                        직급
                      </Form.Label>
                      <select
                        className="form-select"
                        id="positionSelect"
                        name="position"
                        value={formData.position}
                        onChange={handleChange}
                        required
                      >
                        <option value="">선택해주세요.</option>
                        <option value="BASIC">강사</option>
                        <option value="MANAGER">매니저</option>
                        <option value="DIRECTOR">원장</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add2">지점</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.store.name}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">계약형태</Form.Label>
                      <Form.Control
                        type="text"
                        name="workType"
                        value={formData.workType}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group mb-2">
                      <Form.Label>계약일</Form.Label>
                      <div className="form-group">
                        <Flatpickr
                          key={`${formData.contractStartDate}-${formData.contractEndDate}`} // 값이 변경됨에 따라 re-rendering
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: Korean,
                            defaultDate: [
                              formData.contractStartDate ?? "",
                              formData.contractEndDate ?? "",
                            ],
                          }}
                          className="form-control flatpickrdate"
                          placeholder="날짜를 선택해주세요."
                          onChange={(selectedDates) => handleDateChange(selectedDates, 'individual')}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">계약상태</Form.Label>
                      <select
                        className="form-select"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">선택해주세요.</option>
                        <option value="WORK">계약중</option>
                        <option value="QUIT">계약해지</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label className="custom-file-input">계약서 이미지 첨부</Form.Label>
                      <Form.Control type="file" name="contractImage" onChange={handleContractImageUpload} />
                    </Form.Group>
                    {contractView !== '' && contractView !== null && (
                      <Link to={contractView} target="_blank" className="text-success mb-3">
                        현재 계약서 확인
                      </Link>
                    )}
                    {" "}
                    {(contractView !== null && formData.contractImage !== null && formData.contractImage !== contractView) && (
                      <Link to={formData.contractImage} target="_blank" className="text-danger mb-3">
                        등록된 계약서 확인
                      </Link>
                    )}
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Fragment>
  );
});

export default InstructorEdit;
