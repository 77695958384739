import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getInstructorLists = async (storeId, instructorId) => {
  try {
    if (storeId) {
      const response = await apiClient.get(`${API_URL}/admin/instructor/lists`, {
        params: { storeId, instructorId },
      });
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching getInstructorLists:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getInstructorLists");
  }
};

export const getInstructor = async (instructorId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/instructor/${instructorId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching getInstructor:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getInstructor");
  }
};

export const updateInstructor = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/instructor/update`, formData);
    return response.data;
  } catch (error) {
    console.error("Error fetching updateInstructor:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch updateInstructor");
  }
};

export const createInstructor = async (formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/instructor/create`, formData);
    return response.data;
  } catch (error) {
    console.error("Error fetching createInstructor:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch createInstructor");
  }
};

export const getAllInstructorWage = async (storeId, wageDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/instructor/wage`, {
      params: { storeId, wageDate },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getAllInstructorWage:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getAllInstructorWage");
  }
};

export const getInstructorWages = async (instructorId, searchYear) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/instructor/${instructorId}/wages`, {
      params: { searchYear },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getInstructorWages:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getInstructorWages");
  }
}

export const getInstructorWage = async (instructorId, storeId, wageDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/instructor/${instructorId}/wage`, {
      params: { storeId, wageDate },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getInstructorWage:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getInstructorWage");
  }
};

export const updateInstructorWage = async (wageDate) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/instructor/wage`, wageDate);
    return response.data;
  } catch (error) {
    console.error("Error fetching updateInstructorWage:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch updateInstructorWage");
  }
};

export const getInstructorMemo = async (storeId, instructorId) => {
  try {
    if (storeId) {
      const response = await apiClient.get(`${API_URL}/admin/instructor/memo`, {
        params: { storeId, instructorId },
      });
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching getInstructorMemo:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getInstructorMemo");
  }
};

export const updateInstructorMemo = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/instructor/memo`, formData);
    return response.data;
  } catch (error) {
    console.error("Error fetching updateInstructorMemo:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch updateInstructorMemo");
  }
};