import { useState, useEffect } from "react";
import { getLeaveLists } from "../api/leave";

const useLeaveLists = (storeId, year) => {
  const [leaveLists, setLeaveList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (storeId) {
          const data = await getLeaveLists(storeId, year);
          setLeaveList(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeId, year]);

  return { leaveLists, loading, error };
};

export default useLeaveLists;
