import axios from "axios";
import { store } from "../store";
import {
  setAccessToken,
  clearAccessToken,
  clearRefreshToken,
} from "../store/slices/authSlice";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const apiClient = axios.create({
  baseURL: API_URL,
});

apiClient.interceptors.request.use((config) => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

let isErrorHandling = false;

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    console.log("apiClient Error", error);

    if (error.code === 'ERR_NETWORK') {
      window.location.href = '/auth/sign-in';
      alert("현재 서버에 문제가 발생했습니다. 관리자에게 문의해주세요.");
      return Promise.reject(error);
    }

    if (error.response.status === 401 && error.response.data.error) {
      const errorMessage = error.response.data.error;
      
      // "JWT expired"라는 문자열이 에러 메시지에 포함되어 있는지 확인
      if (errorMessage.includes("JWT expired at")) {
        console.log("JWT expired detected. Clear Token");
        store.dispatch(clearAccessToken());
        store.dispatch(clearRefreshToken());

        window.location.href = '/auth/sign-in';
        //alert("Session expired. Please log in again.");
        return Promise.reject(error);
      }
      
      // 다른 401 에러일 경우에도 기본 처리
      // console.log(error);
      // window.location.href = '/auth/sign-in';
      // alert(error);
      // return Promise.reject(error);
    }

    if (isErrorHandling) {
      return Promise.reject(error);
    }
    isErrorHandling = true;

    const state = store.getState();

    console.log('Error status:', error.response.status);
    console.log('Original request retry flag:', originalRequest._retry);

    if (error.response.status === 400 && originalRequest.url.indexOf('/user/login') !== -1) {
      // alert(error);
      return Promise.reject(error);
    }

    // if (error.response.status === 401 && originalRequest.url.indexOf('/user/login') === -1) {
      // console.log(error);
      //window.location.href = '/auth/sign-in'
      // alert(error);
      // return Promise.reject(error);
    // }

    // if (state.auth.accessToken == null) {
      // console.log(error);
      // window.location.href = '/auth/sign-in';
      // return Promise.reject(error);
    // }

    if (error.code === "ERR_NETWORK") {
      alert('현재 일시적으로 서버 문제가 있습니다. 관리자에게 문의해주세요.');
      window.location.href = '/auth/sign-in';
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        console.log("auth", state.auth)
        const refreshToken = state.auth.refreshToken;
        console.log("refreshToken", refreshToken)
        const response = await axios.put(`${API_URL}/user/refresh`, {
          refreshToken: refreshToken,
        });
        const newAccessToken = response.data.accessToken;
        store.dispatch(setAccessToken(newAccessToken));
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        // Retry the original request
        const retryResponse = await axios(originalRequest);

        // If the request is successful, reload the page
        if (retryResponse.status === 200) {
          window.location.reload();
        }
        
        return axios(originalRequest);
      } catch (err) {
        console.log(err)
        store.dispatch(clearAccessToken());
        store.dispatch(clearRefreshToken());

        window.location.href = '/auth/sign-in';
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
