import React, { memo, Fragment, useRef, useEffect } from "react";

//react-bootstrap
import { Row, Col } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import useInstructorLists from "../../../hooks/useInstructorList";
import useUserMe from "../../../hooks/useUserMe";
import { instructorMapping } from "../../../mapping/userMapping";

const InstructorList = memo(() => {
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const myInstructorId = userMeData?.instructor?.id;
  const { instructorList, loading, error } = useInstructorLists(storeId, myInstructorId);

  const columns = useRef([
    {
      data: null,
      title: "이름",
      render: function (data, type, row) {
        let url = `/manage/instructor-edit/${data.id}`;
        return `<a href="${url}">${data.user.name}</a>`;
      },
    },
    { data: "user.email", title: "이메일" },
    { data: "user.phone", title: "연락처" },
    { data: "store.name", title: "지점" },
    {
      data: "position",
      title: "직급",
      render: function (data, type, row) {
        return instructorMapping[data] || data;
      },
    }
  ]);

  const listtableRef = useRef(null);
  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: instructorList,
  });
  useEffect(() => {
    if (listtableRef.current && instructorList.length > 0) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
  
      $("#datatable-ecom2").DataTable({
        data: instructorList,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [instructorList]);

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">강사 리스트</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

InstructorList.displayName = "InstructorList";
export default InstructorList;
