import React from "react";

// default layout
import Default from "../layouts/dashboard/default";

// Dashboard
import Index from "../views/dashboard/index";

// user pages
import Userprofile from "../views/dashboard/user/user-profile";
import UserAdd from "../views/dashboard/user/user-add";
import UserList from "../views/dashboard/user/user-list";
import LeaveList from "../views/dashboard/manage/leave-list";

import AuthRoute from "./auth-router";
import InstructorList from "../views/dashboard/manage/instructor-list";
import UserEdit from "../views/dashboard/user/user-edit";
import LeaveDetail from "../views/dashboard/manage/leave-detail";
import LeaveUse from "../views/dashboard/manage/leave-use";
import ScheduleList from "../views/dashboard/lesson/schedule-list";
import ScheduleAdd from "../views/dashboard/lesson/schedule-add";
import SalesList from "../views/dashboard/sales/sales-list";
import SalesAdd from "../views/dashboard/sales/sales-add";
import SalesEdit from "../views/dashboard/sales/sales-edit";
import InstructorEdit from "../views/dashboard/manage/instructor-edit";
import InstructorAdd from "../views/dashboard/manage/instructor-add";
import InstructorWageList from "../views/dashboard/manage/instructor-wage/instructor-wage-list";
import InstructorWageView from "../views/dashboard/manage/instructor-wage/instructor-wage-view";
import StoreList from "../views/dashboard/store/store-list";
import StoreAdd from "../views/dashboard/store/store-add";
import StoreEdit from "../views/dashboard/store/store-edit";
import LessonReservation from "../views/dashboard/lesson/reservation";
import LessonReservationHistory from "../views/dashboard/lesson/reservation-history";
import UserAnalysis from "../views/dashboard/user/user-analysis";
import SalesAnalysis from "../views/dashboard/sales/sales-analysis";
import UserSurvey from "../views/dashboard/user/user-survey";
import SalesReceiveList from "../views/dashboard/sales/sales-receive-list";
import SalesReceiveLogAdd from "../views/dashboard/sales/sales-receive-add";
import ConfigProgram from "../views/dashboard/config/program.js";
import ConfigLesson from "../views/dashboard/config/lesson";
import ConfigLessonAdd from "../views/dashboard/config/lesson-add";
import ConfigLessonEdit from "../views/dashboard/config/lesson-edit";
import ConfigProgramAdd from "../views/dashboard/config/program-add";
import ConfigProgramEdit from "../views/dashboard/config/program-edit";
import LeaveRequest from "../views/dashboard/manage/leave-request";
import InstructorWageSearch from "../views/dashboard/manage/instructor-wage/instructor-wage-search";
import ConfigGroupLesson from "../views/dashboard/config/group-lesson";
import SalesStatistics from "../views/dashboard/store/sales-statistics";
import InstructorStatistics from "../views/dashboard/store/instructor-statistics";
import InstructorWageMonthSearch from "../views/dashboard/manage/instructor-wage/instructor-wage-month-search";

export const DefaultRouter = [
  {
    path: "/",
    element: <Default />,
    children: [
      {
        path: "",
        element: <AuthRoute element={<Index />} />,
      },

      //User
      {
        path: "/user/user-profile",
        element: <Userprofile />,
        name: "User Profile",
        active: "pages",
        subActive: "User",
      },
      {
        path: "/user/user-add",
        element: <UserAdd />,
        name: "User Add",
        active: "pages",
        subActive: "User",
      },
      {
        path: "/user/user-edit/:userId",
        element: <UserEdit />,
        name: "User Edit",
        active: "pages",
        subActive: "User",
      },
      {
        path: "/user/user-list",
        element: <UserList />,
        name: "User List",
        active: "pages",
        subActive: "User",
      },
      {
        path: "/user/user-analysis",
        element: <UserAnalysis />,
        name: "User Analysis",
        active: "pages",
        subActive: "User",
      },
      {
        path: "/user/user-survey/:userId",
        element: <UserSurvey />,
        name: "User Survey",
        active: "pages",
        subActive: "User",
      },

      // Sales
      {
        path: "/sales/sales-list",
        element: <SalesList />,
        name: "Sales List",
        active: "pages",
        subActive: "Sales",
      },
      {
        path: "/sales/sales-receive-list",
        element: <SalesReceiveList />,
        name: "Sales Receive List",
        active: "pages",
        subActive: "SalesReceive",
      },
      {
        path: "/sales/:salesId/sales-receive-add",
        element: <SalesReceiveLogAdd />,
        name: "Sales Receive Log Add",
        active: "pages",
        subActive: "SalesReceiveAdd",
      },
      {
        path: "/sales/sales-add",
        element: <SalesAdd />,
        name: "Sales Add",
        active: "pages",
        subActive: "Sales",
      },
      {
        path: "/sales/sales-edit/:salesId",
        element: <SalesEdit />,
        name: "Sales Edit",
        active: "pages",
        subActive: "Sales",
      },
      {
        path: "/sales/sales-analysis",
        element: <SalesAnalysis />,
        name: "Sales Analysis",
        active: "pages",
        subActive: "Sales",
      },

      // Lesson
      {
        path: "/lesson/schedule-list",
        element: <ScheduleList />,
        name: "Lesson Schedule List",
        active: "pages",
        subActive: "Lesson",
      },
      {
        path: "/lesson/schedule-add",
        element: <ScheduleAdd />,
        name: "Lesson Schedule Add",
        active: "pages",
        subActive: "Lesson",
      },
      {
        path: "/lesson/reservation",
        element: <LessonReservation />,
        name: "Lesson reservation",
        active: "pages",
        subActive: "Lesson",
      },
      {
        path: "/lesson/reservation-history",
        element: <LessonReservationHistory />,
        name: "Lesson reservation history",
        active: "pages",
        subActive: "Lesson",
      },

      // Manage
      {
        path: "/manage/instructor-list",
        element: <InstructorList />,
        name: "Instructor List",
        active: "pages",
        subActive: "Instructor",
      },
      {
        path: "/manage/instructor-add",
        element: <InstructorAdd />,
        name: "Instructor Add",
        active: "pages",
        subActive: "Instructor",
      },
      {
        path: "/manage/instructor-edit/:instructorId",
        element: <InstructorEdit />,
        name: "Leave Edit",
        active: "pages",
        subActive: "Leave",
      },
      {
        path: "/manage/leave-list",
        element: <LeaveList />,
        name: "Leave List",
        active: "pages",
        subActive: "Leave",
      },
      {
        path: "/manage/leave-use",
        element: <LeaveUse />,
        name: "Leave Use",
        active: "pages",
        subActive: "Leave",
      },
      {
        path: "/manage/leave-detail/:leaveId",
        element: <LeaveDetail />,
        name: "Leave Detail",
        active: "pages",
        subActive: "Leave",
      },
      {
        path: "/manage/leave-request/:instructorId",
        element: <LeaveRequest />,
        name: "Leave Request",
        active: "pages",
        subActive: "Leave",
      },
      {
        path: "/manage/instructor-wage/instructor-search",
        element: <InstructorWageSearch />,
        name: "Instructor Wage Search",
        active: "pages",
        subActive: "Instructor",
      },
      {
        path: "/manage/instructor-wage/month-search",
        element: <InstructorWageMonthSearch />,
        name: "Instructor Wage Search",
        active: "pages",
        subActive: "Instructor",
      },
      {
        path: "/manage/instructor-wage/list",
        element: <InstructorWageList />,
        name: "Instructor Wage List",
        active: "pages",
        subActive: "Instructor",
      },
      {
        path: "/manage/instructor-wage/:instructorId",
        element: <InstructorWageView />,
        name: "Instructor Wage Detail",
        active: "pages",
        subActive: "Instructor",
      },
      
      // Store
      {
        path: "/store/store-list",
        element: <StoreList />,
        name: "Store List",
        active: "pages",
        subActive: "Store",
      },
      {
        path: "/store/store-add",
        element: <StoreAdd />,
        name: "Store Add",
        active: "pages",
        subActive: "Store",
      },
      {
        path: "/store/store-edit/:storeId",
        element: <StoreEdit />,
        name: "Store Edit",
        active: "pages",
        subActive: "Store",
      },
      {
        path: "/store/sales-statistics",
        element: <SalesStatistics />,
        name: "Sales Statistics",
        active: "pages",
        subActive: "Store",
      },
      {
        path: "/store/instructor-statistics",
        element: <InstructorStatistics />,
        name: "Instructor Statistics",
        active: "pages",
        subActive: "Store",
      },

      // Config
      {
        path: "/config/lesson",
        element: <ConfigLesson />,
        name: "config lesson",
        active: "pages",
        subActive: "ConfigLesson",
      },
      {
        path: "/config/lesson/add",
        element: <ConfigLessonAdd />,
        name: "config lesson",
        active: "pages",
        subActive: "ConfigLesson",
      },
      {
        path: "/config/lesson/:id",
        element: <ConfigLessonEdit />,
        name: "config lesson",
        active: "pages",
        subActive: "ConfigLesson",
      },
      {
        path: "/config/program",
        element: <ConfigProgram />,
        name: "config program",
        active: "pages",
        subActive: "ConfigProgram",
      },
      {
        path: "/config/program/add",
        element: <ConfigProgramAdd />,
        name: "config program",
        active: "pages",
        subActive: "ConfigProgram",
      },
      {
        path: "/config/program/:id",
        element: <ConfigProgramEdit />,
        name: "config program",
        active: "pages",
        subActive: "ConfigProgram",
      },
      {
        path: "/config/group-lesson",
        element: <ConfigGroupLesson />,
        name: "config group lesson",
        active: "pages",
        subActive: "ConfigGroupLesson",
      }
    ],
  },
];
