import { useState, useEffect } from "react";
import { getSalesStatistics } from "../../api/sales";

const useSalesStatistics = (startDate, endDate) => {
  const [statisticsData, setStatisticsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSalesStatistics(startDate, endDate);
        setStatisticsData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return { statisticsData, loading, error };
};

export default useSalesStatistics;
