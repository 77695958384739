import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import useUserMe from "../../../hooks/useUserMe";
import { createConfigProgram } from "../../../api/config";
import CommaFormattedInput from "../../../components/form/CommaFormattedInput";

const { memo, useState, useEffect, Fragment } = require("react");

const ConfigProgramAdd = memo(() => {
  const { userMeData } = useUserMe();
  const store = userMeData?.store;

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    isWeekGroup: false,
    weekGroupCount: null,
    storeId: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/config/program");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "isWeekGroup" && checked === false) {
      formData.weekGroupCount = "";
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    formData.storeId = store.id;

    try {
      await createConfigProgram(formData);
      alert("등록되었습니다.");
      setSuccess(true);
      window.location.reload();
    } catch (error) {
      alert(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg="6">
          <Card className="rounded">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">프로그램 등록</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12" className=" mt-2">
                  <div className="table-responsive-lg">
                    <Form>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">프로그램명</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">금액</Form.Label>
                        <CommaFormattedInput
                          name="price"
                          value={formData.price}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <hr/>
                      <Form.Group className="form-group">
                        <Form.Check className="form-switch">
                          <Form.Check.Input
                            type="checkbox"
                            className="me-2"
                            id="trialCheckDefault"
                            name="isWeekGroup"
                            onChange={handleChange}
                          />
                          <Form.Check.Label htmlFor="trialCheckDefault">
                            주N회 그룹레슨인 경우 체크해주세요.
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      {formData.isWeekGroup && (
                        <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">그룹레슨 횟수 입력</Form.Label>
                        <CommaFormattedInput
                          name="weekGroupCount"
                          value={formData.weekGroupCount}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      )}
                      <hr/>
                      <Button
                        type="button"
                        variant="btn btn-primary"
                        onClick={handleRegister}
                        disabled={loading}
                      >
                        등록
                      </Button>{" "}
                      <Button
                        type="button"
                        variant="btn btn-light"
                        onClick={handleBackClick}
                        disabled={loading}
                      >
                        목록
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default ConfigProgramAdd;
