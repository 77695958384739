import React, { memo, Fragment, useRef, useEffect, useState } from "react";

//react-bootstrap
import { Row, Col, Button } from "react-bootstrap";
import Select from "react-select";

//components
import Card from "../../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../../components/hooks/useDatatable";

import $ from "jquery";
import useUserMe from "../../../../hooks/useUserMe";

import './instructor-wage.css'
import { formatNumber } from "../../../../utilities/formatNumber";
import useFetchInstructorList from "../../../../hooks/useFetchInstructorList";
import useInstructorWagesById from "../../../../hooks/useInstructorWagesById";

const InstructorWageSearch = memo(() => {
  const currentYear = new Date().getFullYear();
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const [instructorSelectedData, setInstructorSelectedData] = useState(null);

  const { instructorWageList, loading, error } = useInstructorWagesById(instructorSelectedData, currentYear);

  // 강사 검색 셀렉트
  const { instructorListOption, error: instructorError } = useFetchInstructorList(storeId);

  const handleInstructorSelectedChange = (target) => {
    setInstructorSelectedData(target.value);
  };

  const columns = useRef([
    { data: "instructorUser.name", title: "이름" },
    { data: "wageDate", title: "날짜" },
    { data: "lessonData.individualLessonCount", title: "개인레슨수" },
    { data: "lessonData.groupLessonCount", title: "그룹레슨수" },
    {
      data: "otherPrice",
      title: "기타금액",
      render: function (data, type, row) {
        return formatNumber(data);
      },
    },
    {
      data: "sumPrice",
      title: "총금액",
      render: function (data, type, row) {
        return formatNumber(data);
      },
    },
    {
      data: "sumPrice",
      title: "실수령총금액",
      render: function (data, type, row) {
        return formatNumber(Math.floor(data * 0.967));
      },
    },
  ]);

  const listtableRef = useRef(null);
  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: instructorWageList,
  });
  useEffect(() => {
    if (listtableRef.current && instructorWageList.length > 0) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
  
      $("#datatable-ecom2").DataTable({
        data: instructorWageList,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [instructorWageList]);

  const handleDateChange = (date) => {
    // const formattedDate = wageFormatDate(date[0]);
    // setSearchDate(formattedDate);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">강사별 정산내역</h4>
              </div>
            </Card.Header>
            <Card.Header className="d-flex justify-content-between">
              <div>
                <Select
                  onChange={handleInstructorSelectedChange}
                  options={instructorListOption}
                  placeholder="조회하는 강사를 선택해주세요."
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default InstructorWageSearch;