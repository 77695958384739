import { useState, useEffect, useCallback } from 'react';
import { getStoreList } from '../api/store';

const useStoreList = () => {
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const data = await getStoreList();
      setStoreList(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { storeList, loading, error, refresh: fetchData };
};

export default useStoreList;
