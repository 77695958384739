// src/utils/salesUtils.js

export function handleDefaultChange(e, formData, setFormData) {
  const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      // if (name === "saleType") {
      //   return {
      //     ...prevData, // Maintain other properties that do not need resetting
      //     // userId: "",
      //     // instructorId: "",
      //     //storeId: null,
      //     programId: "",
      //     changeProgramId: "",
      //     saleType: value, // Set the new saleType value
      //     individualCount: 0,
      //     individualType: "",
      //     groupCount: 0,
      //     isWeekGroup: false,
      //     //memo: "",
      //     isStartLesson: false,
      //     issueNumber: "",
      //     isTrial: false,
      //     payment: {
      //       price: 0,
      //       receivePrice: 0,
      //       cardPrice: 0,
      //       bankPrice: 0,
      //       cashPrice: 0,
      //       qrPrice: 0,
      //       voucherPrice: 0,
      //       discount: 0,
      //     },
      //   };
      // }

      if (name.includes(".")) {
        const keys = name.split(".");
        return {
          ...prevData,
          [keys[0]]: {
            ...prevData[keys[0]],
            [keys[1]]: value,
          },
        };
      }

      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
}

export function getChangeProgramPrice() {
  const selectElement = document.querySelector('select[name="changeProgramId"]');

  if (!selectElement) {
    return 0;
  }  

  if (selectElement.selectedIndex === -1) {
    return 0;
  }
  
  const selectedOption = selectElement.options[selectElement.selectedIndex];
  const price = selectedOption.getAttribute('data-price');
  
  return price;
}

export function handleSalesProgram(e, formData, setFormData) {
  const { value } = e.target;
  const selectedOption = e.target.options[e.target.selectedIndex];
  const programPrice = selectedOption.getAttribute("data-price") ?? 0;
  const selectIsWeekGroup = selectedOption.getAttribute("data-isweekgroup") ?? false;
  const weekGroupCount = selectedOption.getAttribute("data-weekgroupcount") ?? 0;

  if (formData.saleType === "소도구+토삭스+의류") {
    alert('"소도구+토삭스+의류"에서는 프로그램을 선택할 수 없습니다.');
    return false;
  }

  const paymentData = formData.payment;
  const totalPaymentPrice = paymentData.cardPrice + paymentData.bankPrice + paymentData.cashPrice + paymentData.qrPrice + paymentData.voucherPrice;
  const changeProgramPrice = getChangeProgramPrice() ?? 0;

  setFormData((prevData) => ({
    ...prevData,
    programId: value,
    isWeekGroup: selectIsWeekGroup,
    weekGroupCount: weekGroupCount,
    groupCount: weekGroupCount,
    payment: {
      ...prevData.payment,
      price: parseFloat(programPrice) - parseFloat(changeProgramPrice),
      receivePrice: parseFloat(programPrice - totalPaymentPrice)
    },
  }));
}

export function handleSalesChangeProgram(e, formData, setFormData) {
  const { value } = e.target;
  const selectedOption = e.target.options[e.target.selectedIndex];
  const prevProgramPrice = selectedOption.getAttribute("data-price");

  const paymentData = formData.payment;
  const totalPaymentPrice = paymentData.cardPrice + paymentData.bankPrice + paymentData.cashPrice +paymentData.qrPrice + paymentData.voucherPrice;

  setFormData((prevData) => ({
    ...prevData,
    changeProgramId: value,
    payment: {
      ...prevData.payment,
      price: paymentData.price - parseFloat(prevProgramPrice),
      receivePrice: paymentData.receivePrice - parseFloat(prevProgramPrice - totalPaymentPrice),
    },
  }));
}

export function handleSalesPaymentChange(e, formData, setFormData) {
  const { name, value } = e.target;
  const updatedPayment = {
    ...formData.payment,
    [name]: parseFloat(value) || 0, // parseFloat를 사용하여 숫자로 변환
  };

  const totalPaymentPrice = 
    updatedPayment.cardPrice +
    updatedPayment.bankPrice +
    updatedPayment.cashPrice +
    updatedPayment.qrPrice +
    updatedPayment.voucherPrice;

  const isSpecialSaleType = formData.saleType === "소도구+토삭스+의류";
  const isRefund = formData.payMethod === "환불";
  
  if (!isSpecialSaleType && !isRefund) {
      const { price, discount } = updatedPayment;
      updatedPayment.receivePrice = price - totalPaymentPrice - discount;
  }  

  setFormData((prevData) => ({
    ...prevData,
    payment: updatedPayment,
  }));
}