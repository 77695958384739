import React, { useState, useContext, memo, Fragment, useEffect } from "react";

//Router
import { Link, useLocation } from "react-router-dom";

//React-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

//Componets
import SidebarMenu from "../../components/sidebar/sidebar-menu";
import { instructorMapping } from "../../../../mapping/userMapping";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(({ userMeData }) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const [active, setActive] = useState("");

  //location
  let location = useLocation();

  // 컴포넌트가 마운트될 때 로컬 스토리지에서 저장된 상태 불러오기
  useEffect(() => {
    const savedActiveMenu = localStorage.getItem("activeAccordion");
    if (savedActiveMenu) {
      setActiveMenu(savedActiveMenu);  // 저장된 값이 있으면 상태에 반영
    }
  }, []);

  // 메뉴 클릭 시 상태 업데이트 및 로컬 스토리지에 저장
  const handleMenuClick = (activeKey) => {
    setActiveMenu(activeKey);
    localStorage.setItem("activeAccordion", activeKey);  // 로컬 스토리지에 상태 저장
  };

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">
              {userMeData.name}
              {instructorMapping[userMeData.instructor.position]}({userMeData.store.name}점) 환영합니다.
            </span>
          </Link>
        </li>
        {userMeData && userMeData.instructor.position !== "BASIC" && (
        <>
        {/* <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">홈</span>
            <span className="mini-icon">-</span>
          </Link>
        </li> */}
        
        <SidebarMenu isTag="true" pathname="/" title="Dashboard">
          <i className="icon">
            <svg
              className="icon-20"
              width="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                fill="currentColor"
              ></path>
            </svg>
          </i>
        </SidebarMenu>
        </>
        )}
        
        <li>
          <hr className="hr-horizontal" />
        </li>

        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">메뉴</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>

        {/* 회원 관리 */}
        <Accordion.Item
          as="li"
          eventKey="sidebar-user"
          bsPrefix={`nav-item ${
            location.pathname === "/user/user-profile"
              ? "active"
              : "" || location.pathname === "/user/user-add"
              ? "active"
              : "" || location.pathname === "/user/user-list"
              ? "active"
              : "" || location.pathname.startsWith("/user/user-edit")
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-user")}
        >
          <CustomToggle eventKey="sidebar-user" onClick={() => handleMenuClick("sidebar-user")}>
            <OverlayTrigger placement="right" overlay={<Tooltip>회원 관리</Tooltip>}>
              <i className="icon">
                <svg
                  className="icon-20"
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">회원 관리</span>
            <i className="right-icon">
              <svg className="icon-18" xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-user" className={activeMenu === "sidebar-user" ? "show" : ""}>
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/user/user-add"
                title="회원 등록"
                minititle="AU"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/user/user-list"
                title="회원 리스트"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/user/user-analysis"
                title="지점별 회원현황"
                minititle="UL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {/* 매출 관리 */}
        <Accordion.Item
          as="li"
          eventKey="sidebar-sales"
          bsPrefix={`nav-item ${
            location.pathname === "/sales/sales-add" ||
            location.pathname === "/sales/sales-list" ||
            location.pathname === "/sales/sales-receive-list" ||
            location.pathname.includes("sales-receive-add")
              ? "active"
              : ""
          }`}
          onClick={() => handleMenuClick("sidebar-sales")}
        >
          <CustomToggle eventKey="sidebar-sales" onClick={() => handleMenuClick("sidebar-sales")}>
            <OverlayTrigger placement="right" overlay={<Tooltip>매출 관리</Tooltip>}>
              <i className="icon">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">매출 관리</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-sales" className={activeMenu === "sidebar-sales" ? "show" : ""}>
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/sales/sales-add"
                title="매입/매출 등록"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/sales/sales-list"
                title="매입/매출 현황"
                minititle="SL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/sales/sales-receive-list"
                title="잔금 현황"
                minititle="SRL"
              ></SidebarMenu>
              {/* <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/sales/sales-analysis"
                title="지점별 매출현황"
                minititle="UL"
              ></SidebarMenu> */}
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {/* 레슨 관리 */}
        <Accordion.Item
          as="li"
          eventKey="sidebar-lesson"
          bsPrefix={`nav-item ${
            location.pathname === "/lesson/user-profile"
              ? "active"
              : "" || location.pathname === "/lesson/schedule-add"
              ? "active"
              : "" || location.pathname === "/lesson/schedule-list"
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-lesson")}
        >
          <CustomToggle eventKey="sidebar-lesson" onClick={() => handleMenuClick("sidebar-lesson")}>
            <OverlayTrigger placement="right" overlay={<Tooltip>레슨 관리</Tooltip>}>
              <i className="icon">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">레슨 관리</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-lesson" className={activeMenu === "sidebar-lesson" ? "show" : ""}>
            <ul className="sub-nav">
              {/* <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/app/user-list"
                title="원장 스케줄"
                minititle="UL"
              ></SidebarMenu> */}
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/lesson/schedule-list"
                title="강사 스케줄"
                minititle="AU"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/lesson/schedule-add"
                title="레슨 등록"
                minititle="UL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {/* 인사 관리 */}
        <Accordion.Item
          as="li"
          eventKey="sidebar-manage"
          bsPrefix={`nav-item ${
            location.pathname === "/manage/instructor-list"
              ? "active"
              : "" || location.pathname === "/manage/instructor-edit"
              ? "active"
              : "" || location.pathname === "/manage/instructor-add"
              ? "active"
              : "" || location.pathname === "/manage/leave-list"
              ? "active"
              : "" || location.pathname === "/manage/user-list"
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-manage")}
        >
          <CustomToggle eventKey="sidebar-manage" onClick={() => handleMenuClick("sidebar-manage")}>
            <OverlayTrigger placement="right" overlay={<Tooltip>인사 관리</Tooltip>}>
              <i className="icon">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">인사 관리</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-manage" className={activeMenu === "sidebar-manage" ? "show" : ""}>
            <ul className="sub-nav">
              {userMeData && userMeData.instructor.position === "DIRECTOR" && (
              <>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-add"
                title="강사 등록"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-list"
                title="강사 리스트"
                minititle="UL"
              ></SidebarMenu>
              </>
              )}
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/leave-list"
                title="휴강 현황"
                minititle="AU"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/leave-use"
                title="휴강 신청"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-wage/list"
                title="수당 관리"
                minititle="UL"
              ></SidebarMenu>
              {userMeData && userMeData.instructor.position === "DIRECTOR" && (
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-wage/instructor-search"
                title="강사별 정산내역"
                minititle="UL"
              ></SidebarMenu>
              )}
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-wage/month-search"
                title="월별 정산내역"
                minititle="UL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {userMeData && (userMeData.instructor.position === "DIRECTOR" || userMeData.role === "ROLE_MASTER") && (
        <>
        <li>
          <hr className="hr-horizontal" />
        </li>

        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">시스템</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        </>
        )}

        {/* 지점 관리 */}
        {userMeData && userMeData.role === "ROLE_MASTER" && (
        <Accordion.Item
          as="li"
          eventKey="sidebar-store"
          bsPrefix={`nav-item ${
            location.pathname === "/store/store-add"
              ? "active"
              : "" || location.pathname === "/store/store-list"
              ? "active"
              : "" || location.pathname === "/store/sales-statistics"
              ? "active"
              : "" || location.pathname === "/store/instructor-statistics"
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-store")}
        >
          <CustomToggle
            eventKey="sidebar-store"
            onClick={() => handleMenuClick("sidebar-store")}
          >
            <OverlayTrigger placement="right" overlay={<Tooltip>지점 관리</Tooltip>}>
              <i className="icon">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">지점 관리</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-store" className={activeMenu === "sidebar-store" ? "show" : ""}>
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/store-add"
                title="지점 등록"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/store-list"
                title="지점 리스트"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/sales-statistics"
                title="지점별 매출통계"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/instructor-statistics"
                title="강사별 레슨통계"
                minititle="UL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        )}

        {/* 설정 */}
        {userMeData && userMeData.instructor.position === "DIRECTOR" && (
        <Accordion.Item
          as="li"
          eventKey="sidebar-config"
          bsPrefix={`nav-item ${
            location.pathname === "/config/price"
              ? "active"
              : "" || location.pathname === "/config/user-add"
              ? "active"
              : "" || location.pathname === "/config/user-list"
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-config")}
        >
          <CustomToggle
            eventKey="sidebar-config"
            onClick={() => handleMenuClick("sidebar-config")}
          >
            <OverlayTrigger placement="right" overlay={<Tooltip>설정</Tooltip>}>
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-20"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.4023 13.58C20.76 13.77 21.036 14.07 21.2301 14.37C21.6083 14.99 21.5776 15.75 21.2097 16.42L20.4943 17.62C20.1162 18.26 19.411 18.66 18.6855 18.66C18.3278 18.66 17.9292 18.56 17.6022 18.36C17.3365 18.19 17.0299 18.13 16.7029 18.13C15.6911 18.13 14.8429 18.96 14.8122 19.95C14.8122 21.1 13.872 22 12.6968 22H11.3069C10.1215 22 9.18125 21.1 9.18125 19.95C9.16081 18.96 8.31259 18.13 7.30085 18.13C6.96361 18.13 6.65702 18.19 6.40153 18.36C6.0745 18.56 5.66572 18.66 5.31825 18.66C4.58245 18.66 3.87729 18.26 3.49917 17.62L2.79402 16.42C2.4159 15.77 2.39546 14.99 2.77358 14.37C2.93709 14.07 3.24368 13.77 3.59115 13.58C3.87729 13.44 4.06125 13.21 4.23498 12.94C4.74596 12.08 4.43937 10.95 3.57071 10.44C2.55897 9.87 2.23194 8.6 2.81446 7.61L3.49917 6.43C4.09191 5.44 5.35913 5.09 6.38109 5.67C7.27019 6.15 8.425 5.83 8.9462 4.98C9.10972 4.7 9.20169 4.4 9.18125 4.1C9.16081 3.71 9.27323 3.34 9.4674 3.04C9.84553 2.42 10.5302 2.02 11.2763 2H12.7172C13.4735 2 14.1582 2.42 14.5363 3.04C14.7203 3.34 14.8429 3.71 14.8122 4.1C14.7918 4.4 14.8838 4.7 15.0473 4.98C15.5685 5.83 16.7233 6.15 17.6226 5.67C18.6344 5.09 19.9118 5.44 20.4943 6.43L21.179 7.61C21.7718 8.6 21.4447 9.87 20.4228 10.44C19.5541 10.95 19.2475 12.08 19.7687 12.94C19.9322 13.21 20.1162 13.44 20.4023 13.58ZM9.10972 12.01C9.10972 13.58 10.4076 14.83 12.0121 14.83C13.6165 14.83 14.8838 13.58 14.8838 12.01C14.8838 10.44 13.6165 9.18 12.0121 9.18C10.4076 9.18 9.10972 10.44 9.10972 12.01Z"
                  fill="currentColor"
                ></path>
              </svg>
            </OverlayTrigger>
            <span className="item-name">설정</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-config" className={activeMenu === "sidebar-config" ? "show" : ""}>
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/config/lesson"
                title="레슨 설정"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/config/program"
                title="프로그램 설정"
                minititle="CL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/config/group-lesson"
                title="그룹레슨 오픈설정"
                minititle="CGL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        )}
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
