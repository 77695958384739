import { useState, useEffect } from 'react';
import { getSalesReceiveLog } from '../../api/sales';

const useSalesReceiveLog = (salesId) => {
  const [salesReceiveLog, setSalesReceiveLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSalesReceiveLog(salesId);
        setSalesReceiveLog(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [salesId]);

  return { salesReceiveLog, loading, error };
};

export default useSalesReceiveLog;
