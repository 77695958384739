export const filterData = (sourceData, template) => {
  const allowedKeys = Object.keys(template);
  let filteredData = {};

  allowedKeys.forEach((key) => {
    if (sourceData.hasOwnProperty(key)) {
      filteredData[key] = sourceData[key];
    }
  });

  // Special handling for nested objects like payment
  if (sourceData.payment) {
    filteredData.payment = {
      ...template.payment,
      ...sourceData.payment,
    };
  }

  return filteredData;
};
