import { useState, useEffect, useCallback } from "react";
import { getConfigProgramList } from "../../api/config";

const useConfigProgramList = (storeId) => {
  const [programList, setProgramList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (storeId) {
        const data = await getConfigProgramList(storeId);
        setProgramList(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [storeId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { programList, loading, error, refresh: fetchData };
};

export default useConfigProgramList;
