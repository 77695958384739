import React, { memo, Fragment, useState } from "react";

//react-bootstrap
import { Row, Form, Button } from "react-bootstrap";
import Select from "react-select";

//components
import Card from "../../../components/bootstrap/card";
import { userRegister } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import useUserMe from "../../../hooks/useUserMe";
import SelectForm from "../../../components/form/SelectForm";
import userOpttions from "../../../json/userOptions.json";
import useFetchInstructorList from "../../../hooks/useFetchInstructorList";

const UserAdd = memo(() => {
  const navigate = useNavigate();
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    birth: "",
    joinPath: "",
    role: "ROLE_USER",
    contractImage: null,
    classification: "",
    active: true,
    storeId: null,
    selectInstructorId: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [checkedPassword, setCheckedPassword] = useState();
  
  // 강사 검색 셀렉트
  const { instructorListOption, error: instructorError } = useFetchInstructorList(storeId);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files : type === "checkbox" ? checked : value,
    }));
  };

  const [instructorSelectedData, setInstructorSelectedData] = useState(null);
  const handleInstructorSelectedChange = (target) => {
    if (target) {
      setInstructorSelectedData(target);
    } else {
      setInstructorSelectedData(null);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    // setSuccess(false);

    if (storeId === undefined || storeId === null) {
      alert("지점 정보를 가져오는데 실패했습니다. 관리자에게 문의해주세요.");
      setLoading(false);
      return false;
    }
    formData.storeId = storeId;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
        alert("유효한 이메일 주소를 입력해주세요.");
        setLoading(false);
        return false;
    }

    const passwordPattern = /^\d{4}$/;
    if (!passwordPattern.test(formData.password)) {
        alert("비밀번호는 4자리 숫자여야 합니다.");
        setLoading(false);
        return false;
    }

    if (formData.password !== checkedPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      setLoading(false);
      return false;
    }

    if (instructorSelectedData != null) {
      formData.selectInstructorId = instructorSelectedData.value;
    } else {
      formData.selectInstructorId = null;
    }

    // console.log(formData);
    // setLoading(false);
    // setSuccess(true);
    // return false;

    try {
      await userRegister(formData);
      alert("등록되었습니다.");
      // setSuccess(true);
      navigate("/user/user-list");
    } catch (error) {
      alert(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">회원 가입</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="new-user-info">
              <Form onSubmit={handleRegister}>
                <div className="row">
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="fname">이름</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="이름을 입력해주세요."
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="add1">연락처</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="핸드폰 번호를 입력해주세요."
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="add2">생년월일</Form.Label>
                    <Form.Control
                      type="text"
                      name="birth"
                      value={formData.birth}
                      onChange={handleChange}
                      placeholder="YYYYMMDD"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 form-group">
                    <SelectForm
                      label="등록경로"
                      name="joinPath"
                      value={formData.joinPath}
                      selectedOptions={userOpttions.joinPath}
                      handleChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="userList">담당 강사</Form.Label>
                    <Select
                      onChange={handleInstructorSelectedChange}
                      options={instructorListOption}
                      placeholder="선택해주세요."
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </div>
                <hr />
                <h5 className="mb-3">계정 정보</h5>
                <div className="row">
                  <Form.Group className="col-md-12 form-group">
                    <Form.Label htmlFor="lname">이메일 (아이디)</Form.Label>
                    <input
                      type="text"
                      name="fake-email"
                      style={{ display: "none" }}
                      autoComplete="off"
                    />
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="예시) hello@medincurl.com"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="pass">비밀번호</Form.Label>
                    <input
                      type="password"
                      name="fake-password"
                      style={{ display: "none" }}
                      autoComplete="new-password"
                    />
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="비밀번호 입력 (숫자 4자리)"
                      maxLength={4}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6 form-group">
                    <Form.Label htmlFor="rpass">비밀번호 확인</Form.Label>
                    <Form.Control
                      type="password"
                      value={checkedPassword}
                      onChange={(e) => setCheckedPassword(e.target.value)}
                      maxLength={4}
                      placeholder="비밀번호 재입력"
                    />
                  </Form.Group>
                </div>
                <Button type="submit" variant="btn btn-primary" disabled={loading}>
                  {loading ? "등록중" : "등록"}
                </Button>
                {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                {/* {success && <p style={{ color: 'green' }}>Registration successful!</p>} */}
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Row>
    </Fragment>
  );
});

UserAdd.displayName = "UserAdd";
export default UserAdd;
