import { useState, useEffect } from 'react';
import { getUserSalesInfo } from '../api/sales';

const useUserSalesInfo = (userId) => {
  const [userSalesInfo, setUserSalesInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserSalesInfo(userId);
        setUserSalesInfo(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return { userSalesInfo, loading, error };
};

export default useUserSalesInfo;
