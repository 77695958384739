import { useState, useEffect } from 'react';
import { getLessonReservationList } from '../../api/lesson';

const useReservationHistory = (userId, startDate, endDate) => {
  const [reservationHistory, setReservationHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) return;

      try {
        const data = await getLessonReservationList(userId, startDate, endDate);
        setReservationHistory(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, startDate, endDate]);

  return { reservationHistory, loading, error };
};

export default useReservationHistory;
