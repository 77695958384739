import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getStoreList = async (storeId, year) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/store/lists`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching getStoreList:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch getStoreList"
    );
  }
};

export const getStore = async (storeId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/store/${storeId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getStore failed");
  }
};

export const createStore = async (formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/store/create`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "createStore failed");
  }
};

export const deleteStore = async (storeId) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/store/${storeId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "deleteStore failed");
  }
}