import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../../../components/bootstrap/card";
import CommaFormattedInput from "../../../../../components/form/CommaFormattedInput";

const InstructorWageIndividualForm = ({ formData, individualLessonDiscountPrice, handleLessnDataChange }) => (
  <Card>
    <Card.Header className="d-flex justify-content-between">
      <div className="header-title">
        <h4 className="card-title">개인레슨</h4>
      </div>
    </Card.Header>
    <Card.Body>
      <div className="new-user-info">
        <div className="row">
          <Form.Group className="col-md-12 form-group">
            <Form.Label htmlFor="fname">금액 합계</Form.Label>
            <CommaFormattedInput
              name="individualPrice"
              value={formData.individualPrice}
              onChange={handleLessnDataChange}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="col-md-6 form-group">
            <Form.Label htmlFor="fname">참여인원</Form.Label>
            <Form.Control
              type="number"
              name="individualCount"
              value={formData.individualCount}
              onChange={handleLessnDataChange}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="col-md-6 form-group">
            <Form.Label htmlFor="fname">운용레슨수</Form.Label>
            <Form.Control
              type="number"
              name="individualLessonCount"
              value={formData.individualLessonCount}
              onChange={handleLessnDataChange}
              disabled={true}
              required
            />
          </Form.Group>
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default InstructorWageIndividualForm;
