import React, { memo, Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import $ from "jquery";
import ReactDOMServer from "react-dom/server";
import EditIcon from "../../../components/icon/EditIcon";
import useSalesList from "../../../hooks/sales/useSalesList";
import { formatDate2 } from "../../../utilities/dateFormatter";
import useUserMe from "../../../hooks/useUserMe";
import { formatNumber } from "../../../utilities/formatNumber";
import { deleteSales } from "../../../api/sales";
import DeleteIcon from "../../../components/icon/DeleteIcon";
import exportToExcel from "../../../utilities/exportToExcel";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import { dateSearch, getTodayDate } from "../../../utilities/dateUtils";

const SalesList = memo(() => {
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const todayDate = getTodayDate();
  const [dateRange, setDateRange] = useState([]);
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const handleSearch = async () => {
    dateSearch(dateRange, (start, end) => {
      setStartDate(start);
      setEndDate(end);
    });
  };
  const { salesList, loading, error, refresh } = useSalesList(storeId, startDate, endDate);

  const listtableRef = useRef(null);

  const handleDelete = useCallback(async (id) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (confirmDelete) {
      try {
        await deleteSales(id);
        alert("삭제되었습니다.");
        refresh(); // 데이터 갱신
      } catch (error) {
        alert(error.message);
      }
    }
  }, [refresh]);

  // 엑셀 내보내기 버튼 핸들러
  const handleExportToExcel = () => {
    const headers = [
      "번호", "이름", "구분", "프로그램", "결제금액", "프로그램금액", "잔금", "작성자", "생성일", "메모"
    ];
    const formattedData = salesList.map((row, index) => ({
      번호: index + 1,
      이름: row.user ? row.user.name : "-",
      구분: row.saleType === '직접입력' ? row.saleTypeInput : row.saleType,
      프로그램: row.program?.name || "-",
      결제금액: formatNumber(row.payment.totalPrice),
      프로그램금액: formatNumber(row.payment.price),
      잔금: formatNumber(row.payment.receivePrice),
      작성자: row.instructorUser?.name || "-",
      생성일: formatDate2(row.createdAt),
      메모: row.memo || "-"
    }));

    // 엑셀 내보내기 호출
    exportToExcel(formattedData, "sales_data", headers);
  };

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    {
      data: null,
      title: "이름",
      render: function (data, type, row) {
        if (data.user) {
          let url = `/user/user-edit/${data.user?.id}`;
          return `<a href="${url}">${data.user?.name}</a>`;
        } else {
          return '-'
        }
      },
    },
    {
      data: null,
      title: "구분",
      render: function (data, type, row) {
        return data.saleType === '직접입력' ? data.saleTypeInput : data.saleType;
      },
    },
    {
      data: null,
      title: "결제방법",
      render: function (data, type, row) {
        return data.payMethod;
      },
    },
    {
      data: "program.name",
      title: "프로그램",
      render: function (data, type, row) {
        return data ? data : "-";
      },
    },
    {
      data: null,
      title: "결제금액",
      render: function (data, type, row) {
        const paymentTotalPrice = data.payment.totalPrice;
        if (data.saleType === '환불') {
          return `<span class="text-danger">${formatNumber(paymentTotalPrice)}</span>`
        }
        return formatNumber(paymentTotalPrice);
      },
    },
    {
      data: null,
      title: "프로그램금액",
      render: function (data, type, row) {
        return formatNumber(data.payment.price);
      },
    },
    // {
    //   data: "payment.refundPrice",
    //   title: "환불금액",
    //   render: function (data, type, row) {
    //     return formatNumber(data);
    //   },
    // },
    {
      data: "payment.receivePrice",
      title: "잔금",
      render: function (data, type, row) {
        if (data > 0) {
          return `<span class="text-danger">${formatNumber(data)}</span>`
        }
        return formatNumber(data);
      },
    },
    {
      data: "instructorUser.name",
      title: "작성자",
      render: function (data, type, row) {
        return data;
      },
    },
    {
      data: "createdAt",
      title: "생성일",
      render: function (data, type, row) {
        return formatDate2(data);
      },
    },
    {
      data: "memo",
      title: "메모",
      render: function (data, type, row) {
        return data ? data : "-";
      },
    },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: (data, type, row) => {
        return `
          <div class="flex align-items-center list-user-action">
            <a class="btn btn-sm btn-icon btn-warning edit-btn" data-id="${data.id}" href="/sales/sales-edit/${data.id}" aria-label="Edit">
              <span class="btn-inner">
                ${ReactDOMServer.renderToString(<EditIcon />)}
              </span>
            </a>
            <a class="btn btn-sm btn-icon btn-danger delete-btn" data-id="${data.id}" href="#" aria-label="Delete">
              <span class="btn-inner">
                ${ReactDOMServer.renderToString(<DeleteIcon />)}
              </span>
            </a>
          </div>`;
      },
    },
  ]);

  useEffect(() => {
    const columnDefs = [];
    if (userMeData && userMeData.instructor.position !== 'DIRECTOR') {
      columnDefs.push({
        targets: 9,  // '유형' 열의 인덱스 (0부터 시작)
        visible: false,
      });
    }
    
    if (listtableRef.current && salesList) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
      }

      const table = $("#datatable-ecom").DataTable({
        data: salesList,
        columns: columns.current,
        columnDefs: columnDefs,
        createdRow: function (row, data, dataIndex) {
          // $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });

      $('#datatable-ecom tbody').on('click', 'a.delete-btn', function (e) {
        e.preventDefault();
        const id = $(this).data('id');
        handleDelete(id);
      });

      return () => {
        table.destroy();
        $('#datatable-ecom tbody').off('click');
      };
    }
  }, [salesList, handleDelete, userMeData]);

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">매입/매출 현황</h4>
              </div>
              {userMeData && userMeData.instructor.position === 'DIRECTOR' && (
              <div>
                <Button
                  type="button"
                  variant="btn btn-success"
                  onClick={handleExportToExcel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>{" "}
                  엑셀 다운로드
                </Button>
              </div>
              )}
            </Card.Header>
            {userMeData && userMeData.instructor.position === 'DIRECTOR' && (
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
                <div>
                  <select
                    className="form-select"
                    name="searchType"
                  >
                    <option value="join">생성일</option>
                  </select>
                </div>
                {/* <div className="form-group mb-0 custom-choicejs" style={{ minWidth: "100px" }}>
                  <ChoicesJs options={searchOptions} className="js-choice" select="one" />
                </div> */}
                <div className="form-group mb-0 " style={{ minWidth: "250px" }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      locale: Korean,
                      defaultDate: new Date()
                    }}
                    className="form-control range_flatpicker"
                    placeholder="날짜를 선택해주세요."
                    onChange={(dates) => setDateRange(dates)}
                  />
                </div>
                <Button type="button" className="primary" onClick={handleSearch}>
                  검색
                </Button>
              </div>
            </Card.Header>
            )}
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

SalesList.displayName = "SalesList";
export default SalesList;
