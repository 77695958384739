import { useNavigate } from "react-router-dom";
import { createInstructor } from "../../../api/instructor";
import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import useUserMe from "../../../hooks/useUserMe";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import CommaFormattedInput from "../../../components/form/CommaFormattedInput";
import useStoreList from "../../../hooks/useStoreList";

const { memo, useState, Fragment } = require("react");

const InstructorAdd = memo(() => {
  const { userMeData, error: userInfoError } = useUserMe();
  const { storeList } = useStoreList();
  const store = userMeData?.store;

  const [instructorData, setInstructorData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    birth: "",
    address: "",
    position: "",
    pay: 0,
    hiddenPay: 0,
    workType: "",
    status: "",
    contractImage: "",
    contractStartDate: "",
    contractEndDate: "",
    storeId: null,
    createdAt: "",
    updatedAt: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [checkedPassword, setCheckedPassword] = useState();

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/manage/instructor-list"); // Navigate to the previous page in the history stack
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInstructorData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = (selectedDates, name) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
      const [startDate, endDate] = formattedDates;
  
      setInstructorData((prevData) => ({
        ...prevData,
        contractStartDate: startDate,
        contractEndDate: endDate
      }));
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(instructorData.email)) {
      alert("유효한 이메일 주소를 입력해주세요.");
      setLoading(false);
      return false;
    }
    if (instructorData.password !== checkedPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      setLoading(false);
      return false;
    }
    if (instructorData.position === "") {
      alert("직급을 선택해주세요.");
      setLoading(false);
      return false;
    }
    if (instructorData.status === "") {
      alert("계약상태를 선택해주세요.");
      setLoading(false);
      return false;
    }

    // 마스터 계정이 아닌 경우 null 데이터 처리
    if (instructorData.storeId == null) {
      instructorData.storeId = store.id;
    }

    const userData = {
      name: instructorData.name,
      email: instructorData.email,
      password: instructorData.password,
      phone: instructorData.phone,
      birth: instructorData.birth,
      role: "ROLE_ADMIN",
      active: true,
      storeId: instructorData.storeId
    }
    instructorData.userReq = userData;

    // console.log(instructorData);
    // setLoading(false);
    // setSuccess(true);
    // return false;

    try {
      await createInstructor(instructorData);
      alert('등록되었습니다.');
      window.location.href = '/manage/instructor-list'
      setSuccess(true);
    } catch (error) {
      alert(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">강사 등록</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleRegister}
                  disabled={loading}
                >
                  저장
                </Button>{" "}
                {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                {/* {success && (
                  <p style={{ color: "green" }}>Registration successful!</p>
                )} */}
                <Button
                  type="button"
                  variant="btn btn-light"
                  onClick={handleBackClick}
                >
                  목록
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <div className="col-md-6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">개인 정보</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="new-user-info">
                <Form onSubmit={handleRegister}>
                  <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">이름</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={instructorData.name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="lname">이메일 (아이디)</Form.Label>
                      <input
                        type="text"
                        name="fake-email"
                        style={{ display: "none" }}
                        autoComplete="off"
                      />
                      <Form.Control
                        type="email"
                        name="email"
                        value={instructorData.email}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="pass">비밀번호</Form.Label>
                      <input
                        type="password"
                        name="fake-password"
                        style={{ display: "none" }}
                        autoComplete="new-password"
                      />
                      <Form.Control
                        type="password"
                        name="password"
                        value={instructorData.password}
                        onChange={handleChange}
                        placeholder="비밀번호 입력"
                        required
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="rpass">비밀번호 확인</Form.Label>
                      <Form.Control
                        type="password"
                        value={checkedPassword}
                        onChange={(e) => setCheckedPassword(e.target.value)}
                        placeholder="비밀번호 재입력"
                        required
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add1">연락처</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={instructorData.phone}
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add2">생년월일</Form.Label>
                      <Form.Control
                        type="text"
                        name="birth"
                        value={instructorData.birth}
                        onChange={handleChange}
                        placeholder="YYYYMMDD"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add2">주소</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={instructorData.address}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">설정</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="new-user-info">
                <Form onSubmit={handleRegister}>
                  <div className="row">
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">고정금액</Form.Label>
                      <CommaFormattedInput
                        name="pay"
                        value={instructorData.pay}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    {userMeData && userMeData.instructor.position === "DIRECTOR" && (
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">고정금액(2번째)</Form.Label>
                      <CommaFormattedInput
                        name="hiddenPay"
                        value={instructorData.hiddenPay}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    )}
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="positionSelect">직급</Form.Label>
                      <select
                        className="form-select"
                        id="positionSelect"
                        name="position"
                        value={instructorData.position}
                        onChange={handleChange}
                        required
                      >
                        <option value="">선택해주세요.</option>
                        <option value="BASIC">강사</option>
                        <option value="MANAGER">매니저</option>
                        <option value="DIRECTOR">원장</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="add2">지점</Form.Label>
                      <select
                        className="form-select"
                        name="storeId"
                        value={instructorData.storeId || (store ? store.id : "")}
                        onChange={handleChange}
                        disabled={userMeData?.role !== 'ROLE_MASTER'}
                      >
                        {store && storeList && storeList.map((row, index) => (
                          <option key={index} value={row.id}>
                            {row.name}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">계약형태</Form.Label>
                      <Form.Control
                        type="text"
                        name="workType"
                        value={instructorData.workType}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group mb-2">
                      <Form.Label>계약일</Form.Label>
                      <div className="form-group">
                        <Flatpickr
                          key={`${instructorData.contractStartDate}-${instructorData.contractEndDate}`} // 값이 변경됨에 따라 re-rendering
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: Korean,
                            defaultDate: [
                              instructorData.contractStartDate ?? "",
                              instructorData.contractEndDate ?? "",
                            ],
                          }}
                          className="form-control flatpickrdate"
                          placeholder="날짜를 선택해주세요."
                          onChange={(selectedDates) => handleDateChange(selectedDates, 'individual')}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">계약상태</Form.Label>
                      <select
                        className="form-select"
                        name="status"
                        value={instructorData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">선택해주세요.</option>
                        <option value="WORK">계약중</option>
                        <option value="QUIT">계약해지</option>
                      </select>
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Fragment>
  );
});

export default InstructorAdd;
