import React, { useState, useEffect, memo, Fragment } from "react";
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Nav, Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";

//apexcharts
import Chart from "react-apexcharts";

//flatpickr
import Flatpickr from "react-flatpickr";

// Redux Selector / Action
import { useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

//Count-up
import CountUp from "react-countup";
import useUserMe from "../../hooks/useUserMe";
import useDashboardData from "../../hooks/useDashboardData";

const Index = memo((props) => {
  const [lessonWeekData, setLessonWeekData] = useState(null);
  const [dashboardDataLoading, setDashboardDataLoading] = useState(true);
  const [salesChartData, setSalesChartData] = useState({
    series: [{
      name: "매출 그래프",
      data: []  // 초기값으로 빈 배열 설정
    }],
    options: {
      chart: {
        type: "line",  // 다른 타입도 가능 ('bar', 'area' 등)
        height: 350
      },
      xaxis: {
        categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
        // title: {
        //   text: '월'
        // }
      },
      yaxis: {
        title: {
          text: '매출금액'
        }
      }
    }
  });

  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  
  // 현재 접속 유저 정보 조회
  const { userMeData } = useUserMe();
  const store = userMeData?.store;

  // 대시보드 데이터 조회
  const { dashboardData } = useDashboardData(userMeData?.store?.id);

  // 일반 회원은 대시보드 페이지 접근 불가
  if (userMeData && userMeData.instructor == null) {
    window.location.href='/lesson/reservation';
  }
  // 마스터 사용안함.
  if (userMeData && userMeData.role === 'ROLE_MASTER') {
    window.location.href='/store/store-list';
  }

  useEffect(() => {
    if (dashboardData) {
      setLessonWeekData(dashboardData.weekLesson);
      setDashboardDataLoading(false);

      const wageData = dashboardData.wageData;

      // 강사별 급여대장 그래프
      if (wageData) {
        // 월 데이터를 고유하게 추출
        const months = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'];
        setCategories(months);

        // 강사별로 데이터를 그룹화하여 시리즈 생성
        setSeries(wageData);
      }

      if (dashboardData.salesPaymentData) {
        const paymentValueData = dashboardData.salesPaymentData.map(item => item.totalMonthlyPrice);
        setSalesChartData(prevState => ({
          ...prevState,
          series: [{
            name: "매출금액",
            data: paymentValueData
          }]
        }));
      }

    }
  }, [dashboardData]);

  const wageDataOptions = {
    chart: {
      type: 'line'
    },
    xaxis: {
      categories: categories
    }
  };

  useSelector(SettingSelector.theme_color);
  const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '20vh' // Or any specific height you want the card to have
  };
  const loadingStyle2 = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '4vh' // Or any specific height you want the card to have
  };

  // chart color setting
  const getVariableColor = () => {
    let prefix = getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const [checked, setChecked] = useState(true);
  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });

  // lessonWeekData 변환
  let chart1 = null;
  if (lessonWeekData) {
    const dates = Object.keys(lessonWeekData);
    const groupData = dates.map((date) => lessonWeekData[date]["GROUP"]);
    const individualData = dates.map((date) => lessonWeekData[date]["INDIVIDUAL"]);
    const categories = dates.map((date) =>
      new Date(date).toLocaleDateString("ko-KR", { weekday: "short" })
    );

    chart1 = {
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: colors,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "28%",
            endingShape: "rounded",
            borderRadius: 3,
          },
        },
        legend: {
          show: true,
          colors: ['#546E7A', '#E91E63']
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },
        grid: {
          show: true,
          strokeDashArray: 7,
        },
        xaxis: {
          categories: categories,
          labels: {
            minHeight: 25,
            maxHeight: 25,
            style: {
              colors: "#8A92A6",
            },
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            minWidth: 20,
            maxWidth: 20,
            style: {
              colors: "#8A92A6",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " lessons";
            },
          },
        },
        responsive: [
          {
            breakpoint: 1025,
            options: {
              chart: {
                height: 130,
              },
            },
          },
        ],
      },
      series: [
        {
          name: "그룹 레슨",
          data: groupData,
        },
        {
          name: "개인 레슨",
          data: individualData,
        },
      ],
    };
  }

  const chart2 = {
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 3,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Sales",
        data: [10, 82, 75, 68, 47, 60, 49, 91, 108],
      },
    ],
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3>ERP 대시보드</h3>
          {/* <p className="text-primary mb-0">Dashboard</p> */}
        </div>
      </div>
      <Row>
        <Col lg="3" md="6">
          <Row>
            <div className="col-12">
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <span className="text-dark">이용중 회원</span>
                    <Link className="badge rounded-pill bg-primary-subtle" to="/user/user-list">
                      View
                    </Link>
                  </div>
                  <h2 className="counter">
                    {dashboardDataLoading && (
                      <div style={loadingStyle2}>
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                    {dashboardData && (
                      <CountUp start={0} end={dashboardData.activeCount} duration={2} useEasing={true} />
                    )}
                  </h2>
                  <small>현재 프로그램을 이용중인 회원입니다.</small>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12">
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <span className="text-dark ">종료 회원</span>
                    <Link className="badge rounded-pill bg-primary-subtle" to="/user/user-list">
                      View
                    </Link>
                  </div>
                  <h2 className="counter">
                    {dashboardDataLoading && (
                      <div style={loadingStyle2}>
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                    {dashboardData && (
                      <CountUp start={0} end={dashboardData.deactiveCount} duration={2} useEasing={true} />
                    )}
                  </h2>
                  <small>프로그램이 종료된 회원입니다.</small>
                </Card.Body>
              </Card>
            </div>
          </Row>
        </Col>
        <Col lg="9" md="12">
          <Card className="card-block card-stretch card-height">
            <Card.Header>
              <div className=" d-flex justify-content-between  flex-wrap">
                <h4 className="card-title">그룹/개인 등록 수</h4>
              </div>
            </Card.Header>
            <Card.Body>
              {dashboardDataLoading && (
                <div style={loadingStyle}>
                  <Spinner animation="border" variant="primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
              {chart1 && (
                <Chart
                  options={chart1.options}
                  series={chart1.series}
                  type="bar"
                  height="100%"
                />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" md="12">
         <Card className="card-block card-stretch card-height">
            <Card.Header>
              <div className=" d-flex justify-content-between  flex-wrap">
                <h4 className="card-title">강사별 급여대장</h4>
              </div>
            </Card.Header>
            <Card.Body>
              {dashboardData?.wageData?.size > 0 ? (
              <Chart options={wageDataOptions} series={series} type="line" height={350} className="dashboard-line-chart"/>
              ) : (
                <div>조건에 해당하는 데이터가 없습니다.</div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" md="12">
         <Card className="card-block card-stretch card-height">
            <Card.Header>
              <div className=" d-flex justify-content-between  flex-wrap">
                <h4 className="card-title">매출 그래프</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart 
                options={salesChartData.options} 
                series={salesChartData.series} 
                type="line" 
                height={350}
                className="dashboard-line-chart"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

Index.displayName = "Index";
export default Index;
