import { useState, useEffect, useCallback } from "react";
import { getSalesList } from "../../api/sales";

const useSalesList = (storeId, startDate, endDate, isReceive) => {
  const [salesList, setSalesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (storeId) {
        const data = await getSalesList(storeId, startDate, endDate, isReceive);
        setSalesList(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [storeId, startDate, endDate, isReceive]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { salesList, loading, error, refresh: fetchData };
};

export default useSalesList;
