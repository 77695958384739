import React, { memo, Fragment, useRef, useEffect, useState, useCallback } from "react";

//react-bootstrap
import { Row, Col, Button, Form } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import useUserLists from "../../../hooks/useUserLists";
import ReactDOMServer from "react-dom/server";

import ListButton from "../../../components/button/ListButton";
import { joinPathMapping } from "../../../mapping/userMapping";
import exportToExcel from "../../../utilities/exportToExcel";
import useUserMe from "../../../hooks/useUserMe";
import PencelIcon from "../../../components/icon/PencelIcon";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale

import { dateSearch } from "../../../utilities/dateUtils";
import { formatDate } from "../../../utilities/dateFormatter";
import DeleteIcon from "../../../components/icon/DeleteIcon";
import { deleteUser } from "../../../api/auth";

const UserList = memo(() => {
  const [dateRange, setDateRange] = useState([]);
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { userLists, loading, error, refresh } = useUserLists(storeId, startDate, endDate);

  const handleDelete = useCallback(async (id) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?\n삭제된 회원은 복구가 불가능합니다.");
    if (confirmDelete) {
      try {
        await deleteUser(id);
        alert("삭제되었습니다.");
        refresh(); // 데이터 갱신
      } catch (error) {
        alert(error.message);
      }
    }
  }, [refresh]);

  // 엑셀 내보내기 버튼 핸들러
  const handleExportToExcel = () => {
    const headers = [
      "번호", "이름", "프로그램", "담당강사", "가입경로", "개인레슨 이용기간", "그룹레슨 이용기간", "가입일", "연락처", "생년월일"
    ];
  
    // 데이터 포맷팅 (엑셀로 내보낼 수 있는 형식으로 변환)
    const formattedData = userLists.map((row, index) => ({
      번호: index + 1,
      이름: row.name || "-",
      프로그램: row.userProgram?.programName ?? "-",
      담당강사: row.instructorUser?.name ?? "-",
      가입경로: joinPathMapping[row.joinPath] || row.joinPath,
      개인레슨이용기간: row.userProgram?.individualStartDate && row.userProgram?.individualEndDate 
        ? `${row.userProgram.individualStartDate} ~ ${row.userProgram.individualEndDate}` 
        : "-",
      그룹레슨이용기간: row.userProgram?.groupStartDate && row.userProgram?.groupEndDate 
        ? `${row.userProgram.groupStartDate} ~ ${row.userProgram.groupEndDate}` 
        : "-",
      가입일: formatDate(row.createdAt),
      연락처: row.phone || "-",
      생년월일: row.birth || "-",
    }));
  
    // 엑셀 내보내기 함수 호출
    exportToExcel(formattedData, "user_list", headers);
  };

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    {
      data: null,
      title: "이름",
      render: function (data, type, row) {
        let url = `/user/user-edit/${data.id}`;
        return `<a href="${url}">${data.name}</a>`;
      },
    },
    {
      data: "userProgram.programName",
      title: "프로그램",
      render: function (data, type, row) {
        return data ?? "-";
      },
    },
    {
      data: "instructorUser.name",
      title: "담당강사",
      render: function (data, type, row) {
        return data ?? "-";
      },
    },
    {
      data: "joinPath",
      title: "가입경로",
      render: function (data, type, row) {
        return joinPathMapping[data] || data;
      },
    },
    {
      data: null,
      title: "개인레슨 이용기간",
      render: function (data, type, row) {
        const startDate = data.userProgram?.individualStartDate ?? "";
        const endDate = data.userProgram?.individualEndDate ?? "";

        if (startDate && endDate) return startDate + " ~ " + endDate;
        else return "-";
      },
    },
    {
      data: null,
      title: "그룹레슨 이용기간",
      render: function (data, type, row) {
        const startDate = data.userProgram?.groupStartDate ?? "";
        const endDate = data.userProgram?.groupEndDate ?? "";
        
        if (startDate && endDate) return startDate + " ~ " + endDate;
        else return "-";
      },
    },
    {
      data: "createdAt",
      title: "가입일",
      render: function (data, type, row) {
        return formatDate(data);
      },
    },
    { data: "phone", title: "연락처" },
    { data: "birth", title: "생년월일" },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "문진표",
      render: function (data, type, row) {
        return ReactDOMServer.renderToString(
          // JSX를 문자열로 변환
          <div className="flex align-items-center list-user-action">
            <Fragment>
              <ListButton
                href={`/user/user-survey/${data.id}`}
                tooltipTitle="Survey"
                ariaLabel="Survey"
                className="btn-secondary"
              >
                <PencelIcon />
              </ListButton>
            </Fragment>
          </div>
        );
      },
    },
    { data: "joinPath", title: "가입경로" },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: (data, type, row) => {
        return `
          <div class="flex align-items-center list-user-action">
            <a class="btn btn-sm btn-icon btn-danger delete-btn" data-id="${data.id}" href="#" aria-label="Delete">
              <span class="btn-inner">
                ${ReactDOMServer.renderToString(<DeleteIcon />)}
              </span>
            </a>
          </div>`;
      },
    },
  ]);

  const listtableRef = useRef(null);

  useEffect(() => {
    const columnDefs = [];
    if (listtableRef.current && userLists) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
      }

      const table = $("#datatable-ecom").DataTable({
        data: userLists,
        columns: columns.current,
        columnDefs: columnDefs,
        createdRow: function (row, data, dataIndex) {
          // $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });

      $('#datatable-ecom tbody').on('click', 'a.delete-btn', function (e) {
        e.preventDefault();
        const id = $(this).data('id');
        handleDelete(id);
      });

      return () => {
        table.destroy();
        $('#datatable-ecom tbody').off('click');
      };
    }
  }, [userLists, handleDelete]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: userLists,
  });

  const handleSearch = async () => {
    dateSearch(dateRange, (start, end) => {
      setStartDate(start);
      setEndDate(end);
    });
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">회원 리스트</h4>
              </div>
              {userMeData && userMeData.instructor.position === 'DIRECTOR' && (
              <div>
                <Button
                  type="button"
                  variant="btn btn-success"
                  onClick={handleExportToExcel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>{" "}
                  엑셀 다운로드
                </Button>
              </div>
              )}
            </Card.Header>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
                {/* <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>검색 옵션</Form.Label>
                </div> */}
                <div>
                  <select
                    className="form-select"
                    name="searchType"
                    // value={""}
                    // onChange={""}
                    required
                  >
                    {/* <option value="">검색 옵션</option> */}
                    <option value="join">가입일</option>
                    {/* <option value="use">이용기간</option> */}
                  </select>
                </div>
                {/* <div className="form-group mb-0 custom-choicejs" style={{ minWidth: "100px" }}>
                  <ChoicesJs options={searchOptions} className="js-choice" select="one" />
                </div> */}
                <div className="form-group mb-0 " style={{ minWidth: "250px" }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      locale: Korean,
                    }}
                    className="form-control range_flatpicker"
                    placeholder="날짜를 선택해주세요."
                    onChange={(dates) => setDateRange(dates)}
                  />
                </div>
                <Button type="button" className="primary" onClick={handleSearch}>
                  검색
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

UserList.displayName = "UserList";
export default UserList;
