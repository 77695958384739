import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getSalesList = async (storeId, startDate, endDate, isReceive) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/sales/lists`, {
      params: { storeId, startDate, endDate, isReceive },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sales lists:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch sales lists");
  }
};

export const getSales = async (id) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/sales/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getSales failed");
  }
};

export const createSales = async (formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/sales/create`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "createSales failed");
  }
};

export const updateSales = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/sales/update`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateSales failed");
  }
};

export const deleteSales = async (id) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/sales/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "deleteSales failed");
  }
};

export const getUserSalesInfo = async (userId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/sales/${userId}/info`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getUserSalesInfo failed");
  }
};

export const getSalesAnalysis = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/sales/analysis`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch getSalesAnalysis");
  }
};

export const getSalesReceiveLog = async (salesId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/sales/${salesId}/receive-log`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch getSalesReceiveLog");
  }
};

export const createSalesReceiveLog = async (formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/sales/receive-log`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "createSalesReceiveLog failed");
  }
};

export const updateUserProgram = async (salesId, lessonUseStatus) => {
  const formData = {
    salesId: salesId,
    lessonUseStatus: lessonUseStatus
  }
  try {
    const response = await apiClient.put(`${API_URL}/admin/sales/program/${salesId}`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateUserProgram failed");
  }
}

export const getSalesStatistics = async (startDate, endDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/sales/store-statistics`, {
      params: { startDate, endDate},
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getSalesStatistics failed");
  }
};