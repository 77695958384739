import React, { memo, Fragment, useRef, useEffect, useState } from "react";

//react-bootstrap
import { Row, Col, Button, Form } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import exportToExcel from "../../../utilities/exportToExcel";
import useUserAnalysisList from "../../../hooks/useUserAnalysisList";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import { dateSearch } from "../../../utilities/dateUtils";
import useUserMe from "../../../hooks/useUserMe";
import ChoicesJs from "../../../components/choices";
import useConfigProgramList from "../../../hooks/config/useConfigProgramList";

const UserAnalysis = memo(() => {
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const [searchType, setSearchType] = useState("");
  const [searchProgram, setSearchProgram] = useState("");
  const { userAnalysisData, loading, error } = useUserAnalysisList(storeId, searchType, searchProgram);
  const { programList } = useConfigProgramList(storeId);
  const [dateRange, setDateRange] = useState([]);

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    { data: "user.name", title: "이름" },
    { data: "userProgram.programName", title: "프로그램" },
    {
      data: null,
      title: "개인레슨 이용기간",
      render: function (data, type, row) {
        const startDate = data.userProgram?.individualStartDate ?? "";
        const endDate = data.userProgram?.individualEndDate ?? "";

        if (startDate && endDate) return startDate + " ~ " + endDate;
        else return "-";
      },
    },
    {
      data: null,
      title: "그룹레슨 이용기간",
      render: function (data, type, row) {
        const startDate = data.userProgram?.groupStartDate ?? "";
        const endDate = data.userProgram?.groupEndDate ?? "";
        
        if (startDate && endDate) return startDate + " ~ " + endDate;
        else return "-";
      },
    },
  ]);

  const listtableRef = useRef(null);

  useEffect(() => {
    if (listtableRef.current && userAnalysisData) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
      $("#datatable-ecom2").DataTable({
        data: userAnalysisData,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [userAnalysisData]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: userAnalysisData,
  });

  const handleSearchType = async (e) => {
    const { name, value, type, checked } = e.target;
    setSearchType(value);
  }

  const handleSearchProgram = async (e) => {
    const { name, value, type, checked } = e.target;
    setSearchProgram(value);
  }

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">지점별 회원현황</h4>
              </div>
            </Card.Header>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3 pb-4">
                <div>
                  <select
                    className="form-select"
                    name="searchType"
                    onChange={handleSearchType}
                    required
                  >
                    <option value="ING">유효회원</option>
                    <option value="END">지난달 종료회원</option>
                    <option value="END_SCHEDULE">이번달 종료예정회원</option>
                  </select>
                </div>
                <div>
                <select
                  className="form-select"
                  name="programId"
                  onChange={handleSearchProgram}
                >
                  <option value="">프로그램 선택</option>
                  {programList && programList.map((row, index) => (
                    <option key={index} value={row.id} data-price={row.price} data-isweekgroup={row.isWeekGroup} data-weekgroupcount={row.weekGroupCount}>
                      {row.name}
                    </option>
                  ))}
                </select>
                </div>
                {/* <div className="form-group mb-0 " style={{ minWidth: "250px" }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      locale: Korean,
                    }}
                    className="form-control range_flatpicker"
                    placeholder="날짜를 선택해주세요."
                    onChange={(dates) => setDateRange(dates)}
                  />
                </div> */}
                {/* <Button type="button" className="primary" onClick={() => alert(1)}>
                  검색
                </Button> */}
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

UserAnalysis.displayName = "UserAnalysis";
export default UserAnalysis;
