import { useState, useEffect } from 'react';
import { getUserLists } from '../api/auth';

const useFetchUserList = (storeId) => {
  const [userListOption, setUserListOption] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const result = await getUserLists(storeId);
        const transformedOptions = result.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        setUserListOption(transformedOptions);
      } catch (err) {
        setError(err);
      }
    };

    if (storeId) {
      fetchUserList();
    }
  }, [storeId]);

  return { userListOption, error };
};

export default useFetchUserList;
