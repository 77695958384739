import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getConfigLessonList = async (storeId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/config/lesson`, {
      params: { storeId }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getConfigLessonList:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getConfigLessonList");
  }
};

export const getConfigLesson = async (id) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/config/lesson/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch getConfigLesson");
  }
};

export const createConfigLesson = async (formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/config/lesson`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to createConfigLesson");
  }
};

export const updateConfigLesson = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/config/lesson`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to updateConfigLesson");
  }
};

export const deleteConfigLesson = async (id) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/config/lesson/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to deleteConfigLesson");
  }
}

export const getConfigProgramList = async (storeId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/config/program`, {
      params: { storeId }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getConfigProgramList:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getConfigProgramList");
  }
}

export const getConfigProgram = async (id) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/config/program/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch getConfigProgram");
  }
};

export const createConfigProgram = async (formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/config/program`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to createConfigProgram");
  }
};

export const updateConfigProgram = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/config/program`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to updateConfigProgram");
  }
};

export const deleteConfigProgram = async (id) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/config/program/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to deleteConfigProgram");
  }
}

export const getConfigSystem = async (storeId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/config/system`, {
      params: { storeId }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getConfigSystem:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getConfigSystem");
  }
}

export const updateConfigSystem = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/config/system`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to updateConfigSystem");
  }
}