import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Form, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import ReactDOMServer from "react-dom/server";

import EditIcon from "../../../components/icon/EditIcon";
import ListButton from "../../../components/button/ListButton";

import useSalesList from "../../../hooks/sales/useSalesList";
import {  formatDate2 } from "../../../utilities/dateFormatter";
import useUserMe from "../../../hooks/useUserMe";
import { formatNumber } from "../../../utilities/formatNumber";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import { dateSearch } from "../../../utilities/dateUtils";

const SalesReceiveList = memo(() => {
  const { userMeData, isLoading } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [dateRange, setDateRange] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleSearch = async () => {
    dateSearch(dateRange, (start, end) => {
      setStartDate(start);
      setEndDate(end);
    });
  };

  // if (userMeData && userMeData.instructor.position !== 'DIRECTOR') {
  //   window.location.href='/';
  // }
  
  const { salesList, loading, error } = useSalesList(storeId, startDate, endDate, true);

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    {
      data: null,
      title: "이름",
      render: function (data, type, row) {
        return data.user ? data.user.name : "-";
      },
    },
    {
      data: "saleType",
      title: "구분",
      render: function (data, type, row) {
        return data;
      },
    },
    {
      data: "program.name",
      title: "프로그램",
      render: function (data, type, row) {
        return data ? data : "-";
      },
    },
    {
      data: "payment.price",
      title: "금액",
      render: function (data, type, row) {
        return formatNumber(data);
      },
    },
    {
      data: "payment.receivePrice",
      title: "잔금",
      render: function (data, type, row) {
        if (data > 0) {
          return `<span class="text-danger">${formatNumber(data)}</span>`
        }
        return formatNumber(data);
      },
    },
    {
      data: "instructorUser.name",
      title: "담당강사",
      render: function (data, type, row) {
        return data;
      },
    },
    {
      data: "createdAt",
      title: "결제일",
      render: function (data, type, row) {
        return formatDate2(data);
      },
    },
    {
      data: "memo",
      title: "메모",
      render: function (data, type, row) {
        return data ? data : "-";
      },
    },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: function (data, type, row) {
        return ReactDOMServer.renderToString(
          // JSX를 문자열로 변환
          <div className="flex align-items-center list-user-action">
            <Fragment>
              <ListButton
                href={`/sales/${data.id}/sales-receive-add`}
                tooltipTitle="Add"
                ariaLabel="Add"
                className="btn-warning"
              >
                <EditIcon />
              </ListButton>
            </Fragment>
          </div>
        );
      },
    },
  ]);

  const listtableRef = useRef(null);
  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: salesList,
  });
  useEffect(() => {
    if (listtableRef.current && salesList.length > 0) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
  
      $("#datatable-ecom2").DataTable({
        data: salesList,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [salesList]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">잔금 현황</h4>
              </div>
            </Card.Header>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
                <div>
                  <select
                    className="form-select"
                    name="searchType"
                  >
                    <option value="join">생성일</option>
                  </select>
                </div>
                {/* <div className="form-group mb-0 custom-choicejs" style={{ minWidth: "100px" }}>
                  <ChoicesJs options={searchOptions} className="js-choice" select="one" />
                </div> */}
                <div className="form-group mb-0 " style={{ minWidth: "250px" }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      locale: Korean,
                    }}
                    className="form-control range_flatpicker"
                    placeholder="날짜를 선택해주세요."
                    onChange={(dates) => setDateRange(dates)}
                  />
                </div>
                <Button type="button" className="primary" onClick={handleSearch}>
                  검색
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom2"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

SalesReceiveList.displayName = "SalesReceiveList";
export default SalesReceiveList;
