import React from 'react';
import { Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

const CommaFormattedInput = ({ name, value, onChange, className, disabled, placeholder }) => {
  const handleValueChange = (values) => {
    const { value: rawValue } = values;
    
    // Check if onChange is a function before calling it
    if (typeof onChange === 'function') {
      onChange({ target: { name, value: rawValue } });
    }
  };

  return (
    <NumericFormat
      value={value}
      thousandSeparator=","
      //prefix="₩"
      onValueChange={handleValueChange}
      customInput={Form.Control}
      name={name}
      className={className}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default CommaFormattedInput;