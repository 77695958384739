import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearAccessToken } from '../../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      dispatch(clearAccessToken());
      navigate('/auth/sign-in');
    };

    handleLogout();
  }, [dispatch, navigate]);

  return null; // or you can return a message like "Logging out..."
};

export default Logout;
