import React, { memo, Fragment, useState, useEffect, useRef } from "react";
import useReservationHistory from "../../../hooks/lesson/useReservationHistory";

//react-bootstrap
import { Row, Col, Form, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import { dateSearch } from "../../../utilities/dateUtils";
import useUserMe from "../../../hooks/useUserMe";
import { lessonLogStatusMapping, lessonTypeMapping } from "../../../mapping/lessonMapping";
import { getUserMe } from "../../../api/auth";

const LessonReservationHistory = memo(() => {
  const [dateRange, setDateRange] = useState([]);
  const { userMeData } = useUserMe();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [userInfoData, setUserInfoData] = useState(null);
  const [userProgramData, setUserProgramData] = useState(null);

  // Use the reservation history hook only when userInfoData is available
  const { reservationHistory, loading, error } = useReservationHistory(userMeData?.id, startDate, endDate);

  // 사용자 정보 조회
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserInfoData(userData);
        setUserProgramData(userData.userProgram);
        setStoreId(userData.store.id);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };
    fetchUserData();
  }, []);

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    {
      data: "lessonType",
      title: "구분",
      render: function (data, type, row) {
        return lessonTypeMapping[data] || data;
      },
    },
    { data: "lessonDate", title: "날짜" },
    { data: "lessonTime", title: "시간" },
    {
      data: "status",
      title: "상태",
      render: function (data, type, row) {
        return lessonLogStatusMapping[data] || data;
      },
    },
  ]);

  const listtableRef = useRef(null);

  useEffect(() => {
    if (listtableRef.current && reservationHistory) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
      $("#datatable-ecom2").DataTable({
        data: reservationHistory,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [reservationHistory]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: reservationHistory,
  });

  const handleSearch = async () => {
    dateSearch(dateRange, (start, end) => {
      setStartDate(start);
      setEndDate(end);
    });
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <div className="header-title">
                  <h4 className="card-title">레슨이력 확인</h4>
                </div>
              </div>
            </Card.Header>
            <Card.Header className="d-flex justify-content-between">
             <div>
              <h6 className="mb-0">
                  개인레슨 이용기간: {userProgramData && 
                    (userProgramData.individualStartDate && userProgramData.individualEndDate 
                      ? `${userProgramData.individualStartDate}~${userProgramData.individualEndDate}` 
                      : "-")
                  }
                </h6>
                <h6 className="mb-0">
                  개인레슨 잔여횟수: {userProgramData && userProgramData.individualCount}
                </h6>
                <br />
                <h6 className="mb-0">
                  그룹레슨 이용기간: {userProgramData && 
                    `${userProgramData.groupStartDate}~${userProgramData.groupEndDate}`
                  }
                </h6>
                <h6 className="mb-0">
                  그룹레슨 잔여횟수: {userProgramData && userProgramData.groupCount}
                </h6>
              </div>
            </Card.Header>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
                <div className="form-group mb-0 " style={{ minWidth: "210px" }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      locale: Korean,
                    }}
                    className="form-control range_flatpicker"
                    placeholder="날짜를 선택해주세요."
                    onChange={(dates) => setDateRange(dates)}
                  />
                </div>
                <Button type="button" className="primary" onClick={handleSearch}>
                  검색
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom2"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

LessonReservationHistory.displayName = "LessonReservationHistory";
export default LessonReservationHistory;
