import { useState, useEffect, useCallback } from "react";
import { getInstructor } from "../api/instructor";

const useInstructorInfo = (instructorId) => {
  const [instructorData, setInstructorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (instructorId) {
        const data = await getInstructor(instructorId);
        const transInstructorData = {
          id: data.id,
          userId: data.user.id,
          name: data.user.name,
          email: data.user.email,
          phone: data.user.phone,
          birth: data.user.birth,
          address: data.address,
          position: data.position,
          pay: data.pay,
          hiddenPay: data.hiddenPay,
          workType: data.workType,
          status: data.status,
          contractImage: data.contractImage,
          contractStartDate: data.contractStartDate,
          contractEndDate: data.contractEndDate,
          store: data.store,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        }
        setInstructorData(transInstructorData);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [instructorId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { instructorData, refresh: fetchData };
};

export default useInstructorInfo;
