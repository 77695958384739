import React, { memo, Fragment, useRef, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import useDataTable from "../../../components/hooks/useDatatable";
import $ from "jquery";
import ReactDOMServer from "react-dom/server";
import EditIcon from "../../../components/icon/EditIcon";
import DeleteIcon from "../../../components/icon/DeleteIcon";
import useConfigLessonList from "../../../hooks/config/useConfigLessonList";
import useUserMe from "../../../hooks/useUserMe";
import { deleteConfigLesson } from "../../../api/config";

const ConfigLesson = memo(() => {
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const { lessonList, loading, error, refresh } = useConfigLessonList(storeId);

  const listtableRef = useRef(null);

  const handleDelete = useCallback(async (id) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (confirmDelete) {
      try {
        await deleteConfigLesson(id);
        alert("삭제되었습니다.");
        refresh(); // 데이터 갱신
      } catch (error) {
        alert(error.message);
      }
    }
  }, [refresh]);

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    { data: "lessonTypeName", title: "레슨 유형" },
    { data: "lessonOption", title: "레슨 옵션" },
    { data: "price", title: "금액" },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: (data, type, row) => {
        return `
          <div class="flex align-items-center list-user-action">
            <a class="btn btn-sm btn-icon btn-warning edit-btn" data-id="${data.id}" href="/config/lesson/${data.id}" aria-label="Edit">
              <span class="btn-inner">
                ${ReactDOMServer.renderToString(<EditIcon />)}
              </span>
            </a>
            <a class="btn btn-sm btn-icon btn-danger delete-btn" data-id="${data.id}" href="#" aria-label="Delete">
              <span class="btn-inner">
                ${ReactDOMServer.renderToString(<DeleteIcon />)}
              </span>
            </a>
          </div>`;
      },
    },
  ]);

  useEffect(() => {
    if (listtableRef.current && lessonList) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
      }

      const table = $("#datatable-ecom").DataTable({
        data: lessonList,
        columns: columns.current,
        language: {
          emptyTable: "데이터가 없습니다."
        },
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });

      $('#datatable-ecom tbody').on('click', 'a.delete-btn', function (e) {
        e.preventDefault();
        const id = $(this).data('id');
        handleDelete(id);
      });

      return () => {
        table.destroy();
        $('#datatable-ecom tbody').off('click');
      };
    }
  }, [lessonList, handleDelete]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: lessonList,
  });

  const handleCreatePage = () => {
    window.location.href = '/config/lesson/add'
  }

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">레슨 설정</h4>
              </div>
              <div>
                <Button type="button" variant="btn btn-primary" onClick={handleCreatePage}>
                  추가
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

ConfigLesson.displayName = "ConfigLesson";
export default ConfigLesson;
