import { useState, useEffect } from 'react';
import { getSalesAnalysis } from '../../api/sales';

const useSalesAnalysisList = (storeId) => {
  const [salesAnalysisData, setSalesAnalysisData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSalesAnalysis();
        setSalesAnalysisData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { salesAnalysisData, loading, error };
};

export default useSalesAnalysisList;
