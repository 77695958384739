import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import useUserMe from "../../../hooks/useUserMe";
import { getConfigProgram, updateConfigProgram, updateConfigSystem } from "../../../api/config";
import CommaFormattedInput from "../../../components/form/CommaFormattedInput";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import useConfigSystem from "../../../hooks/config/useConfigSystem";

const { memo, useState, useEffect, Fragment } = require("react");

const ConfigGroupLesson = memo(() => {
  const { userMeData } = useUserMe();
  const store = userMeData?.store;
  const [formData, setFormData] = useState({
    storeId: "",
    groupLessonOpenTime: "",
    groupLessonDayWeek: "",
  });
  const [loading, setLoading] = useState(false);

  // 지점 시스템 정보 조회
  const { systemData } = useConfigSystem(store?.id);
  useEffect(() => {
    if (systemData) {
      setFormData(systemData);
    }
  }, [systemData]); // systemData가 변경될 때만 setFormData 호출  
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const hours = selectedTime.getHours().toString().padStart(2, "0");
    const minutes = selectedTime.getMinutes().toString().padStart(2, "0");
    const formattedTime = hours + ":" + minutes;

    setFormData((prevData) => ({
      ...prevData,
      groupLessonOpenTime: formattedTime
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    formData.storeId = store.id;

    try {
      await updateConfigSystem(formData);
      alert("수정되었습니다.");
      window.location.reload();
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg="6">
          <Card className="rounded">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">프로그램 수정</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12" className=" mt-4">
                  <div className="table-responsive-lg">
                    <Form>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="typeSelect">레슨 종류</Form.Label>
                        <select
                          className="form-select"
                          name="groupLessonDayWeek"
                          value={formData.groupLessonDayWeek}
                          onChange={handleChange}
                          required
                        >
                          <option value="">요일을 선택해주세요.</option>
                          <option value="MONDAY">월요일</option>
                          <option value="TUESDAY">화요일</option>
                          <option value="WEDNESDAY">수요일</option>
                          <option value="THURSDAY">목요일</option>
                          <option value="FRIDAY">금요일</option>
                          <option value="SATURDAY">토요일</option>
                          <option value="SUNDAY">일요일</option>
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label>시간</Form.Label>
                        <div className="form-group">
                          <Flatpickr
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                              locale: "ko",
                              minuteIncrement: 10, // 10분 단위로 설정,
                              defaultDate: formData.groupLessonOpenTime
                            }}
                            className="form-control flatpickrtime"
                            value={formData.groupLessonOpenTime}
                            placeholder="시간을 선택해주세요."
                            onChange={handleTimeChange}
                            required
                          />
                        </div>
                      </Form.Group>
                      <hr/>
                      <Button
                        type="button"
                        variant="btn btn-primary"
                        onClick={handleRegister}
                        disabled={loading}
                      >
                        수정
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default ConfigGroupLesson;
