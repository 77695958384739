/**
 * 강사 스케줄에서 사용하는 메모
 */

import { useEffect, useState } from "react";
import { getInstructorMemo } from "../../api/instructor";

const useInstructorLessonMemoList = (storeId, instructorId, allView) => {
  const [instructorMemoList, setInstructorMemoList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (storeId && instructorId != null) {
          if (allView) {
            const data = await getInstructorMemo(storeId, instructorId);
            setInstructorMemoList(data);
          } else {
            const data = await getInstructorMemo(storeId, instructorId);
            setInstructorMemoList(data);
          }
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeId, instructorId, allView]);

  return { instructorMemoList, loading, error };
};

export default useInstructorLessonMemoList;