import React, { useState } from "react";
import { Form, Button, Row } from "react-bootstrap";
import Select from "react-select";
import Card from "../../../../components/bootstrap/card";
import CommaFormattedInput from "../../../../components/form/CommaFormattedInput";
import SelectForm from "../../../../components/form/SelectForm";

const SalesAddForm = ({
  formData,
  salesOptions,
  handleChange,
  handlePaymentChange,
  handleInstructorSelectedChange,
  handleUserSelectedChange,
  handleProgram,
  handleChangeProgram,
  instructorListOption,
  userListOption,
  programList,
  lessonOptions,
  priceDisable
}) => (
  <Row>
    <div className="col-md-6">
      <div className="row">
        <div className="col-12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">회원 관련 데이터</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Form>
                <div className="row">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="userList">회원등록</Form.Label>
                    <Select
                      onChange={handleUserSelectedChange}
                      options={userListOption}
                      placeholder="선택해주세요."
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">프로그램</Form.Label>
                    <select
                      className="form-select"
                      name="programId"
                      value={formData.programId}
                      onChange={handleProgram}
                    >
                      <option value="">선택해주세요.</option>
                      {programList && programList.map((row, index) => (
                        <option key={index} value={row.id} data-price={row.price} data-isweekgroup={row.isWeekGroup} data-weekgroupcount={row.weekGroupCount}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  {formData.saleType === '변경' && (
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">기존 프로그램 (변경시 선택)</Form.Label>
                    <select
                      className="form-select"
                      name="changeProgramId"
                      value={formData.changeProgramId}
                      onChange={handleChangeProgram}
                    >
                      <option value="">선택해주세요.</option>
                      {programList && programList.map((row, index) => (
                        <option key={index} value={row.id} data-price={row.price}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  )}
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="lname">할인금액</Form.Label>
                    <CommaFormattedInput
                      name="discount"
                      value={formData.payment.discount}
                      onChange={handlePaymentChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">옵션</Form.Label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      name="individualType"
                      value={formData.individualType}
                      onChange={handleChange}
                    >
                      <option value="">선택해주세요.</option>
                      {lessonOptions.map((option, index) => (
                        <option key={index} value={option.lessonOption}>
                          {/* ({option.lessonTypeName}) {option.lessonOption} */}
                          {option.lessonOption}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">개인 횟수</Form.Label>
                    <Form.Control
                      type="number"
                      name="individualCount"
                      value={formData.individualCount}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">그룹 횟수</Form.Label>
                    <Form.Control
                      type="number"
                      name="groupCount"
                      value={formData.groupCount}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Check className="form-switch">
                      <Form.Check.Input
                        type="checkbox"
                        className="me-2"
                        id="trialCheckDefault"
                        name="isStartLesson"
                        onChange={handleChange}
                      />
                      <Form.Check.Label htmlFor="trialCheckDefault">레슨 시작 유무</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Check className="form-switch">
                      <Form.Check.Input
                        type="checkbox"
                        className="me-2"
                        id="trialCheckDefault"
                        name="isTrial"
                        onChange={handleChange}
                      />
                      <Form.Check.Label htmlFor="trialCheckDefault">체험레슨 등록 유무</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">매입/매출 데이터</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <Form>
            <div className="row">
              <Form.Group className="form-group">
                <SelectForm
                  label="구분"
                  name="saleType"
                  value={formData.saleType}
                  selectedOptions={salesOptions.saleType}
                  handleChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <SelectForm
                  label="결제방법"
                  name="payMethod"
                  value={formData.payMethod}
                  selectedOptions={salesOptions.payMethod}
                  handleChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">금액</Form.Label>
                <CommaFormattedInput
                  name="price"
                  value={formData.payment.price}
                  onChange={handlePaymentChange}
                  disabled={formData.saleType !== "소도구+토삭스+의류"}
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">
                  <div className="text-danger">잔금</div>
                </Form.Label>
                <CommaFormattedInput
                  name="receivePrice"
                  value={formData.payment.receivePrice}
                  onChange={handlePaymentChange}
                  disabled={formData.saleType !== "소도구+토삭스+의류"}
                  className="text-danger"
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">카드</Form.Label>
                <CommaFormattedInput
                  name="cardPrice"
                  value={formData.payment.cardPrice}
                  onChange={handlePaymentChange}
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">계좌</Form.Label>
                <CommaFormattedInput
                  name="bankPrice"
                  value={formData.payment.bankPrice}
                  onChange={handlePaymentChange}
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">현금</Form.Label>
                <CommaFormattedInput
                  name="cashPrice"
                  value={formData.payment.cashPrice}
                  onChange={handlePaymentChange}
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">QR</Form.Label>
                <CommaFormattedInput
                  name="qrPrice"
                  value={formData.payment.qrPrice}
                  onChange={handlePaymentChange}
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">바우처</Form.Label>
                <CommaFormattedInput
                  name="voucherPrice"
                  value={formData.payment.voucherPrice}
                  onChange={handlePaymentChange}
                  required
                />
              </Form.Group>
              <Form.Group className="form-group col-md-6">
                <Form.Label htmlFor="lname">현/영 발급번호</Form.Label>
                <Form.Control
                  type="text"
                  name="issueNumber"
                  value={formData.issueNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="userList">작성자</Form.Label>
                <Select
                  onChange={handleInstructorSelectedChange}
                  options={instructorListOption}
                  placeholder="선택해주세요."
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="lname">메모</Form.Label>
                <Form.Control
                  as="textarea"
                  name="memo"
                  value={formData.memo}
                  onChange={handleChange}
                  rows="5"
                />
              </Form.Group>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  </Row>
);

export default SalesAddForm;
