import React, { memo, Fragment, useState, useEffect } from "react";
import { Row, Form, Button, Col, Badge } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import salesOptions from "../../../json/salesOptions.json";

// api
import { getUserMe } from "../../../api/auth";
import { createSales } from "../../../api/sales";
import SalesAddForm from "./component/SalesAddForm";
import useFetchUserList from "../../../hooks/useFetchUserList";
import useFetchInstructorList from "../../../hooks/useFetchInstructorList";
import useConfigProgramList from "../../../hooks/config/useConfigProgramList";
import { handleDefaultChange, handleSalesChangeProgram, handleSalesPaymentChange, handleSalesProgram } from './utils/salesUtils';
import { useLessonData } from "../../../hooks/lesson/useLessonData";

const SalesAdd = memo(() => {
  const [formData, setFormData] = useState({
    userId: null,
    instructorId: "",
    storeId: null,
    programId: null ?? "",
    changeProgramId: "",
    saleType: "",
    payMethod: "",
    individualCount: 0,
    individualType: "",
    groupCount: 0,
    isWeekGroup: false,
    memo: "",
    isStartLesson: false,
    issueNumber: "",
    isTrial: false,
    payment: {
      price: 0,
      receivePrice: 0,
      cardPrice: 0,
      bankPrice: 0,
      cashPrice: 0,
      qrPrice: 0,
      voucherPrice: 0,
      discount: 0,
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    handleDefaultChange(e, formData, setFormData);
  };

  const handleProgram = (e) => {
    handleSalesProgram(e, formData, setFormData); // Use the utility function
  };

  const handleChangeProgram = (e) => {
    handleSalesChangeProgram(e, formData, setFormData);
  };

  const handlePaymentChange = (e) => {
    handleSalesPaymentChange(e, formData, setFormData);
  };

  const [userSelectedData, setUserSelectedData] = useState(null);
  const handleUserSelectedChange = (target) => {
    setUserSelectedData(target.value);
  };

  const [instructorSelectedData, setInstructorSelectedData] = useState(null);
  const handleInstructorSelectedChange = (target) => {
    setInstructorSelectedData(target.value);
  };

  const [userInfoData, setUserInfoData] = useState();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserInfoData(userData);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
  }, []);
  const storeId = userInfoData?.instructor?.storeId;

  // 클래스 (프로그램) 리스트
  const { programList } = useConfigProgramList(storeId);

  // 레슨 종류 및 옵션 config 연결
  const { lessonTypes, lessonOptions } = useLessonData(userInfoData?.instructor.storeId);

  // 회원 검색 셀렉트
  const { userListOption, error: userListError } = useFetchUserList(storeId);

  // 강사 검색 셀렉트
  const { instructorListOption, error: instructorError } = useFetchInstructorList(storeId);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    formData.userId = userSelectedData;
    formData.instructorId = instructorSelectedData;
    formData.storeId= storeId;

    // console.log(formData);
    // setLoading(false);
    // setSuccess(true);
    // return false;

    if (formData.saleType === '') {
      setLoading(false);
      alert("구분을 선택해주세요.");
      return false;
    }
    if (formData.payMethod === '') {
      setLoading(false);
      alert("결제방법을 선택해주세요.");
      return false;
    }

    if (formData.instructorId === null || formData.instructorId === undefined) {
      setLoading(false);
      alert("작성자를 선택해주세요.");
      return false;
    }

    try {
      await createSales(formData);
      setSuccess(true);
      alert("등록되었습니다.");
      window.location.href = "/sales/sales-list";
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <div className="col-md-12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">매입/매출 등록페이지</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleRegister}
                  disabled={loading}
                >
                  {loading ? "등록중..." : "등록"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
      <SalesAddForm
        formData={formData}
        salesOptions={salesOptions}
        handleChange={handleChange}
        handlePaymentChange={handlePaymentChange}
        handleInstructorSelectedChange={handleInstructorSelectedChange}
        handleUserSelectedChange={handleUserSelectedChange}
        handleProgram={handleProgram}
        handleChangeProgram={handleChangeProgram}
        instructorListOption={instructorListOption}
        userListOption={userListOption}
        programList={programList}
        lessonOptions={lessonOptions}
      />
    </Fragment>
  );
});

SalesAdd.displayName = "SalesAdd";
export default SalesAdd;
