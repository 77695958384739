import React from 'react';
import { Form } from 'react-bootstrap';

const RadioButton = ({ label, options, fieldName, selectedValue, handleChange }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <div className="d-flex flex-wrap gap-2 mb-3">
        {options.map((option, index) => (
          <Form.Check key={index} className="d-block">
            <Form.Check.Input
              type="radio"
              className="me-2"
              name={fieldName}
              id={`${fieldName}-${index}`}
              value={option}
              checked={selectedValue === option}
              onChange={handleChange}
            />
            <Form.Check.Label htmlFor={`${fieldName}-${index}`}>
              {option}
            </Form.Check.Label>
          </Form.Check>
        ))}
      </div>
    </Form.Group>
  );
};

export default RadioButton;
