import React, { memo, Fragment, useRef, useEffect, useState } from "react";

//react-bootstrap
import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

import { dateSearch, dateTypeSearch, getTodayDate } from "../../../utilities/dateUtils";

import $ from "jquery";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
//import "flatpickr/dist/plugins/monthSelect/style.css"; // MonthSelect style
import useSalesStatistics from "../../../hooks/store/useSalesStatistics";
import useDataTable from "../../../components/hooks/useDatatable";
import { formatNumber } from "../../../utilities/formatNumber";
import exportToExcel from "../../../utilities/exportToExcel";

const SalesStatistics = memo(() => {
  const todayDate = getTodayDate();
  const [dateRange, setDateRange] = useState([]);
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [dateUnit, setDateUnit] = useState("일"); // 기본값: 일단위
  const { statisticsData, loading, error } = useSalesStatistics(startDate, endDate);
  const dateInputRef = useRef(null); // 연도 입력 필드를 참조하기 위한 Ref

  const handleSearch = async () => {
    const dateValue = dateInputRef.current?.flatpickr.input.value; // 연도 입력값 가져오기
    dateTypeSearch(dateRange, dateUnit, dateValue, (start, end) => {
      setStartDate(start);
      setEndDate(end);
    });
  };

  const listtableRef = useRef(null);

  const columns = useRef([
    { data: "storeName", title: "지점" },
    {
      data: "totalSales",
      title: "매출금액",
      render: function (data, type, row) {
        return formatNumber(data);
      },
    },
    { data: "totalIndividualLessons", title: "개인레슨" },
    { data: "totalGroupLessons", title: "그룹레슨" },
    {
      data: null,
      title: "레슨총합",
      render: function (data, type, row, meta) {
        return data.totalIndividualLessons + data.totalGroupLessons;
      },
    },
  ]);

  useEffect(() => {
    if (listtableRef.current && statisticsData) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
      }

      const table = $("#datatable-ecom").DataTable({
        data: statisticsData,
        columns: columns.current,
        language: {
          emptyTable: "데이터가 없습니다."
        },
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });

      return () => {
        table.destroy();
        $('#datatable-ecom tbody').off('click');
      };
    }
  }, [statisticsData]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: statisticsData,
  });

  // Flatpickr 옵션을 단위에 따라 변경하는 함수 (일단위는 range 사용)
  const getFlatpickrOptions = () => {
    switch (dateUnit) {
      case "월":
        return {
          dateFormat: "Y-m", // 월단위
          locale: Korean,
          defaultDate: [todayDate],
          plugins: [
            new monthSelectPlugin({
              shorthand: true, // 월 이름 짧게
              dateFormat: "Y-m", // 저장되는 형식
              altFormat: "F Y", // UI에 표시되는 형식 (e.g., January 2024)
            }),
          ],
          disableMobile: "true"
        };
      case "년":
        return {
          dateFormat: "Y", // 년단위
          locale: Korean,
          defaultDate: [todayDate],
          altInput: true, // 대체 입력창 사용
          altFormat: "Y", // UI에 표시되는 형식 (e.g., 2023, 2024)
          allowInput: true, // 직접 입력 가능
          noCalendar: true, // 달력 비활성화 (연도만 선택 가능)
        };  
      default: // 일단위 (range 사용)
        return {
          mode: "range",  // 일단위에서는 range 모드 사용
          dateFormat: "Y-m-d", // 기본 일단위
          locale: Korean,
          defaultDate: [todayDate], // 현재 날짜 기본 선택
        };
    }
  };

  // 엑셀 내보내기 버튼 핸들러
  const handleExportToExcel = () => {
    const headers = [
      "지점", "매출금액", "개인레슨", "그룹레슨", "레슨총합"
    ];
  
    // 데이터 포맷팅 (엑셀로 내보낼 수 있는 형식으로 변환)
    const formattedData = statisticsData.map((row, index) => ({
      지점: row.storeName || "-",
      매출금액: row.totalSales || 0,
      개인레슨: row.totalIndividualLessons,
      그룹레슨: row.totalGroupLessons,
      레슨총합: row.totalIndividualLessons + row.totalGroupLessons,
    }));
  
    // 엑셀 내보내기 함수 호출
    exportToExcel(formattedData, "sales-statistics", headers);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">지점별 매출통계</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-success"
                  onClick={handleExportToExcel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>{" "}
                  엑셀 다운로드
                </Button>
              </div>
            </Card.Header>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
                {/* 추가: 일, 월, 년 단위 선택을 위한 Dropdown */}
                <DropdownButton
                  id="dropdown-basic-button"
                  title={`기간 옵션: ${dateUnit}`}
                  variant="gray"
                  onSelect={(eventKey) => setDateUnit(eventKey)}
                >
                  <Dropdown.Item eventKey="일">일단위</Dropdown.Item>
                  <Dropdown.Item eventKey="월">월단위</Dropdown.Item>
                  <Dropdown.Item eventKey="년">년단위</Dropdown.Item>
                </DropdownButton>

                <div className="form-group mb-0 " style={{ minWidth: "250px" }}>
                  {/* key 속성을 추가하여 단위가 변경될 때 Flatpickr가 다시 렌더링되도록 설정 */}
                  <Flatpickr
                    ref={dateInputRef} // Ref를 사용하여 연도 입력 필드를 참조
                    key={dateUnit} // key가 변경될 때마다 새로 렌더링
                    options={getFlatpickrOptions()} // 단위에 따른 Flatpickr 옵션 변경
                    className="form-control range_flatpicker"
                    placeholder="날짜를 선택해주세요."
                    onChange={(selectedDates) => setDateRange(selectedDates)}  // 날짜 범위 설정
                  />
                </div>
                <Button type="button" className="primary" onClick={handleSearch}>
                  검색
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

SalesStatistics.displayName = "SalesStatistics";
export default SalesStatistics;
