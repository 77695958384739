import React, { memo, Fragment, useState, useEffect, useRef, useCallback } from "react";

//react-bootstrap
import { Row, Col, Form, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale

// api
import { getUserLists, getUserMe } from "../../../api/auth";
import { createLesson } from "../../../api/lesson";
import { getConfigLessonList } from "../../../api/config";

const ScheduleAdd = memo(() => {
  const [lessonData, setLessonData] = useState({
    instructorId: "",
    storeId: "",
    lessonType: "",
    lessonTypeName: "",
    lessonOption: "",
    count: "",
    lessonDate: "",
    lessonTime: "",
    userList: [],
    memo: "",
  });
  const [lessonDate, setLessonDate] = useState();
  const [lessonTime, setLessonTime] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [userSelectedData, setUserSelectedData] = useState();
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "",
    individualCount: 0,
    individualType: "",
    groupCount: 0,
  });
  const [userInfoData, setUserInfoData] = useState();

  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserInfoData(userData);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
  }, []);

  // 레슨 종류 및 옵션 config 연결
  const [lessonTypes, setLessonTypes] = useState([]);
  const [lessonOptions, setLessonOptions] = useState([]);
  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const configData = await getConfigLessonList(userInfoData.instructor.storeId);

        // lessonType 고유 값 추출
        const types = configData.reduce((acc, item) => {
          if (!acc.some((type) => type.lessonType === item.lessonType)) {
            acc.push({ lessonType: item.lessonType, lessonTypeName: item.lessonTypeName });
          }
          return acc;
        }, []);
        setLessonTypes(types);

        // lessonOption 고유 값 추출 (null 값 제외)
        const options = [
          ...new Set(
            configData
              .map((item) => ({
                lessonOption: item.lessonOption,
                lessonType: item.lessonType,
                lessonTypeName: item.lessonTypeName,
              }))
              .filter((option) => option.lessonOption !== null && option.lessonOption !== '')
          ),
        ];
        setLessonOptions(options);
      } catch (error) {
        console.error("Failed to fetch getConfigPriceList", error);
      }
    };
    if (userInfoData && userInfoData.instructor.storeId) {
      fetchConfigData();
    }
  }, [userInfoData]);

  const userSelectRef = useRef(null); // Create a ref to the Select component
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'lessonType') {
      userSelectRef.current.clearValue();
    }

    setLessonData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUserSelectedChange = (target) => {
    const selectValue = [];

    target.forEach((select) => {
      selectValue.push({
        name: select.label,
        value: select.value,
        individualCount: select.individualCount, // individualCount를 추가
        individualType: select.individualType,
        groupCount: select.groupCount // groupCount를 추가
      });
    });

    setUserSelectedData(selectValue);
  };

  // 회원 검색 셀렉트
  const fetchUserList = useCallback(async () => {
    if (userInfoData && userInfoData.instructor.id) {
      try {
        const result = await getUserLists(userInfoData.instructor.storeId);
        const transformedOptions = result.map((user) => ({
          value: user.id,
          label: `${user.name} (${user.phone}) | ${user.userProgram?.individualCount ?? 0}/${user.userProgram?.groupCount ?? 0}`,
          individualCount: user.userProgram?.individualCount ?? 0,
          individualType: user.userProgram?.individualType ?? "",
          groupCount: user.userProgram?.groupCount ?? 0,
        }));
  
        setSelectedOption(transformedOptions);
      } catch (error) {
        console.error("Failed to fetchUserList", error);
      }
    }
  }, [userInfoData]); // userInfoData에 의존성 추가  
  useEffect(() => {
    fetchUserList();
  }, [userInfoData, fetchUserList]);

  useEffect(() => {
    if (lessonData.lessonType === "INDIVIDUAL") {
      setLessonData((prevState) => ({
        ...prevState,
        count: 1,
      }));
    } else if (lessonData.count === 1) {
      setLessonData((prevState) => ({
        ...prevState,
        count: "",
      }));
    }
  }, [lessonData.lessonType, lessonData.count]);

  // Handler for date range selection
  const handleDateChange = (selectedDates) => {
    const formattedDates = selectedDates.map((date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    });
    setLessonDate(formattedDates[0]);
  };
  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const hours = selectedTime.getHours().toString().padStart(2, "0");
    const minutes = selectedTime.getMinutes().toString().padStart(2, "0");
    const formattedTime = hours + ":" + minutes;

    setLessonTime(formattedTime);
  };

  const handleScheduleAdd = async (e) => {
    e.preventDefault();
    lessonData.lessonDate = lessonDate;
    lessonData.lessonTime = lessonTime;

    if (lessonData.lessonType === "") {
      alert("레슨 종류를 선택해주세요.");
      return false;
    }
    if (lessonData.lessonDate === "" || lessonData.lessonDate === undefined) {
      alert("날짜를 입력해주세요.");
      return false;
    }
    if (lessonData.lessonTime === "" || lessonData.lessonTime === undefined) {
      alert("시간을 입력해주세요.");
      return false;
    }
    if (Number(lessonData.count) === 0) {
      alert("인원제한은 최소 1명이상 되어야 합니다.");
      return false;
    }
    if (userSelectedData && Number(lessonData.count) < userSelectedData.length) {
      alert("참여인원이 설정된 인원제한보다 많습니다.");
      return false;
    }

    lessonData.instructorId = userInfoData.instructor.id;
    lessonData.storeId = userInfoData.instructor.storeId;

    const userValueList = []
    for (let i = 0; i < userSelectedData.length; i++) {
      const select = userSelectedData[i];
      const userName = select.name.split('(')[0].trim();

      if (lessonData.lessonType === 'INDIVIDUAL' && select.individualCount === 0) {
        alert(`${userName} 회원은 잔여 개인 횟수가 없습니다.`);
        return false;
      }
      if (lessonData.lessonType === 'GROUP' && select.groupCount === 0) {
        alert(`${userName} 회원은 잔여 그룹 횟수가 없습니다.`);
        return false;
      }
      if (lessonData.lessonType === 'INDIVIDUAL' && 
          lessonData.lessonOption !== '' && 
          lessonData.lessonOption !== select.individualType) {
        alert(`${userName} 회원의 개인레슨 옵션은 ${select.individualType}입니다.`);
        return false;
      }
      userValueList.push(select.value);
    }
    lessonData.userList = userValueList;

    try {
      await createLesson(lessonData);
      alert("등록되었습니다.");
      
      // 등록 시 선택된 유저만 초기화
      lessonData.userList = [];
      setLessonData(lessonData);
      userSelectRef.current.clearValue();
      fetchUserList(); 

      console.log(lessonData);
    } catch (error) {
      alert(error.message);
      console.error("Error deleting leave log:", error.message);
    }
  };

  const handleLessonList = () => {
    navigate("/lesson/schedule-list"); // Navigate to the previous page in the history stack
  };

  return (
    <Fragment>
      <Row>
        <Col lg="6">
          <Card className="rounded">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">레슨 등록</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12" className=" mt-4">
                  <div className="table-responsive-lg">
                    <Form onSubmit={handleScheduleAdd}>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label>날짜</Form.Label>
                        <div className="form-group">
                          <Flatpickr
                            options={{ minDate: "today", dateFormat: "Y-m-d", locale: Korean }}
                            className="form-control flatpickrdate"
                            placeholder="날짜를 선택해주세요."
                            onChange={handleDateChange}
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label>시간</Form.Label>
                        <div className="form-group">
                          <Flatpickr
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                              locale: "ko",
                              minuteIncrement: 30, // 30분 단위로 설정
                            }}
                            className="form-control flatpickrtime"
                            placeholder="시간을 선택해주세요."
                            onChange={handleTimeChange}
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="typeSelect">레슨 종류</Form.Label>
                        <select
                          className="form-select"
                          id="typeSelect"
                          name="lessonType"
                          value={lessonData.lessonType}
                          onChange={handleChange}
                          required
                        >
                          <option value="">선택해주세요.</option>
                          {lessonTypes.map((type, index) => (
                            <option key={index} value={type.lessonType}>
                              {type.lessonTypeName}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="typeSelect">레슨 옵션</Form.Label>
                        <select
                          className="form-select"
                          id="typeSelect"
                          name="lessonOption"
                          value={lessonData.lessonOption}
                          onChange={handleChange}
                        >
                          <option value="">선택해주세요.</option>
                          {lessonOptions.map((option, index) => (
                            <option key={index} value={option.lessonOption}>
                              ({option.lessonTypeName}) {option.lessonOption}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="count">인원제한</Form.Label>
                        <Form.Control
                          type="number"
                          name="count"
                          value={lessonData.count}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="userList">회원 선택</Form.Label>
                        <Select
                          ref={userSelectRef}
                          onChange={handleUserSelectedChange}
                          options={selectedOption}
                          placeholder="선택해주세요."
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isMulti
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">메모</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="memo"
                          value={lessonData.memo}
                          onChange={handleChange}
                          rows="3"
                        />
                      </Form.Group>
                      <Button type="submit" variant="btn btn-primary" disabled={loading}>
                        {loading ? "Registering..." : "등록"}
                      </Button>
                      {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                      {/* {success && <p style={{ color: "green" }}>Registration successful!</p>}{" "} */}
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

ScheduleAdd.displayName = "ScheduleAdd";
export default ScheduleAdd;
