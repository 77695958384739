import React from 'react';
import { Form } from 'react-bootstrap';

const SelectForm = ({ label, name, value, selectedOptions, handleChange }) => {
  const options = Object.keys(selectedOptions).map(key => ({
    value: key,
    label: selectedOptions[key]
  }));

  return (
    <Form.Group className="col-md-12  form-group">
      <Form.Label htmlFor={name+"Select"}>{label}</Form.Label>
      <select
        className="form-select"
        name={name}
        value={value}
        onChange={handleChange}
        required
      >
        <option value="">선택해주세요.</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Form.Group>
  );
};

export default SelectForm;