import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthRoute = ({ element }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  return accessToken ? element : <Navigate to="/auth/sign-in" />;
};

export default AuthRoute;