import React, { useState, useContext, memo, Fragment, useEffect } from "react";

//Router
import { Link, useLocation } from "react-router-dom";

//React-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

//Componets
import SidebarMenu from "../../components/sidebar/sidebar-menu";
import { instructorMapping } from "../../../../mapping/userMapping";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNavMaster = memo(({ userMeData }) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const [active, setActive] = useState("");

  //location
  let location = useLocation();

  // 컴포넌트가 마운트될 때 로컬 스토리지에서 저장된 상태 불러오기
  useEffect(() => {
    const savedActiveMenu = localStorage.getItem("activeAccordion");
    if (savedActiveMenu) {
      setActiveMenu(savedActiveMenu);  // 저장된 값이 있으면 상태에 반영
    }
  }, []);

  // 메뉴 클릭 시 상태 업데이트 및 로컬 스토리지에 저장
  const handleMenuClick = (activeKey) => {
    setActiveMenu(activeKey);
    localStorage.setItem("activeAccordion", activeKey);  // 로컬 스토리지에 상태 저장
  };

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">
              {userMeData.name}님 환영합니다.
            </span>
          </Link>
        </li>

        {/* 인사 관리 */}
        <Accordion.Item
          as="li"
          eventKey="sidebar-manage"
          bsPrefix={`nav-item ${
            location.pathname === "/manage/instructor-list"
              ? "active"
              : "" || location.pathname === "/manage/instructor-edit"
              ? "active"
              : "" || location.pathname === "/manage/instructor-add"
              ? "active"
              : "" || location.pathname === "/manage/leave-list"
              ? "active"
              : "" || location.pathname === "/manage/user-list"
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-manage")}
        >
          <CustomToggle eventKey="sidebar-manage" onClick={() => handleMenuClick("sidebar-manage")}>
            <OverlayTrigger placement="right" overlay={<Tooltip>인사 관리</Tooltip>}>
              <i className="icon">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">인사 관리</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-manage" className={activeMenu === "sidebar-manage" ? "show" : ""}>
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-add"
                title="강사 등록"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/manage/instructor-list"
                title="강사 리스트"
                minititle="UL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        <li>
          <hr className="hr-horizontal" />
        </li>

        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">시스템</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>

        {/* 지점 관리 */}
        {userMeData && userMeData.role === "ROLE_MASTER" && (
        <Accordion.Item
          as="li"
          eventKey="sidebar-store"
          bsPrefix={`nav-item ${
            location.pathname === "/store/store-add"
              ? "active"
              : "" || location.pathname === "/store/store-list"
              ? "active"
              : "" || location.pathname === "/store/sales-statistics"
              ? "active"
              : "" || location.pathname === "/store/instructor-statistics"
              ? "active"
              : ""
          } `}
          onClick={() => handleMenuClick("sidebar-store")}
        >
          <CustomToggle
            eventKey="sidebar-store"
            onClick={() => handleMenuClick("sidebar-store")}
          >
            <OverlayTrigger placement="right" overlay={<Tooltip>지점 관리</Tooltip>}>
              <i className="icon">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </i>
            </OverlayTrigger>
            <span className="item-name">지점 관리</span>
            <i className="right-icon">
              <svg
                className="icon-18"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-store" className={activeMenu === "sidebar-store" ? "show" : ""}>
            <ul className="sub-nav">
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/store-add"
                title="지점 등록"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/store-list"
                title="지점 리스트"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/sales-statistics"
                title="지점별 매출통계"
                minititle="UL"
              ></SidebarMenu>
              <SidebarMenu
                isTag="false"
                staticIcon="true"
                pathname="/store/instructor-statistics"
                title="강사별 레슨통계"
                minititle="UL"
              ></SidebarMenu>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        )}
      </Accordion>
    </Fragment>
  );
});

VerticalNavMaster.displayName = "VerticalNavMaster";
export default VerticalNavMaster;
