import React from 'react';
import PropTypes from 'prop-types';
// import './Button.css';


const ListButton = ({ href, tooltipTitle, ariaLabel, className, children, onClick }) => {
  return (
    <a
      className={`btn btn-sm btn-icon ${className}`}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      href={href}
      aria-label={ariaLabel}
      data-bs-original-title={tooltipTitle}
      style={{ marginRight: '5px' }}
      onClick={onClick}
    >
      <span className="btn-inner">
        {children}
      </span>
    </a>
  );
};

ListButton.propTypes = {
  href: PropTypes.string,
  tooltipTitle: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ListButton;