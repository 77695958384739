import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../../../components/bootstrap/card";
import CommaFormattedInput from "../../../../../components/form/CommaFormattedInput";

const InstructorWageOtherForm = ({
  formData,
  handleOtherDataChange,
  addOtherData,
  removeOtherData
}) => (
  <Card>
    <Card.Header className="d-flex justify-content-between">
      <div className="header-title">
        <h4 className="card-title">기타</h4>
      </div>
    </Card.Header>
    <Card.Body>
      {formData.otherData && formData.otherData.map((data, index) => (
        <Row key={index} className="mb-3">
          <Col>
            <Form.Control
              type="text"
              placeholder="항목"
              value={data.name}
              onChange={(e) => handleOtherDataChange(index, 'name', e.target.value)}
            />
          </Col>
          <Col>
            <CommaFormattedInput
              placeholder="금액"
              value={data.price}
              onChange={(e) => handleOtherDataChange(index, 'price', e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <Button variant="danger" onClick={() => removeOtherData(index)}>
              -
            </Button>
          </Col>
        </Row>
      ))}
      <Button variant="primary" onClick={addOtherData}>
        +
      </Button>
    </Card.Body>
  </Card>
);

export default InstructorWageOtherForm;
