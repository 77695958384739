import { useState, useEffect } from 'react';
import { getConfigSystem } from '../../api/config';

const useConfigSystem = (storeId) => {
  const [systemData, setSystemData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getConfigSystem(storeId);
        setSystemData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (storeId) {
      fetchData();
    }
  }, [storeId]);

  return { systemData, loading, error };
};

export default useConfigSystem;
