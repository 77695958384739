import React, { memo, Fragment, useRef, useEffect, useState } from "react";

//react-bootstrap
import { Row, Col, Button } from "react-bootstrap";

//components
import Card from "../../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../../components/hooks/useDatatable";

import $ from "jquery";
import useUserMe from "../../../../hooks/useUserMe";
import useInstructorWages from "../../../../hooks/useInstructorWages";

//flatpicker
import Flatpickr from "react-flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js"; // Import 한국어 locale
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import './instructor-wage.css'
import { formatNumber } from "../../../../utilities/formatNumber";

const InstructorWageMonthSearch = memo(() => {
  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");

  // 지난달 계산
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  const lastMonthYear = lastMonthDate.getFullYear();
  const lastMonth = String(lastMonthDate.getMonth() + 1).padStart(2, "0");

  const [searchDate, setSearchDate] = useState(`${currentYear}-${currentMonth}`);
  const { userMeData, loading: userMeLoading } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const { instructorWageList, loading, error } = useInstructorWages(storeId, searchDate);

  // DIRECTOR가 아닌 경우, 데이터를 필터링함
  const filteredWageList = userMeData?.instructor?.position !== 'DIRECTOR'
  ? instructorWageList.filter(wage => wage.instructorId === userMeData?.instructor?.id)
  : instructorWageList;

  const columns = useRef([
    { data: "instructorUser.name", title: "이름" },
    { data: "wageDate", title: "날짜" },
    { data: "lessonData.individualLessonCount", title: "개인레슨수" },
    { data: "lessonData.groupLessonCount", title: "그룹레슨수" },
    {
      data: "otherPrice",
      title: "기타금액",
      render: function (data, type, row) {
        return formatNumber(data);
      },
    },
    {
      data: "sumPrice",
      title: "총금액",
      render: function (data, type, row) {
        return formatNumber(data);
      },
    },
    {
      data: "sumPrice",
      title: "실수령총금액",
      render: function (data, type, row) {
        return formatNumber(Math.floor(data * 0.967));
      },
    },
  ]);

  const listtableRef = useRef(null);
  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: filteredWageList,
  });
  useEffect(() => {
    if (listtableRef.current && filteredWageList.length > 0) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
  
      $("#datatable-ecom2").DataTable({
        data: filteredWageList,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [filteredWageList]);

  // Flatpickr options 조건부 설정
  const getFlatpickrOptions = () => {
    if (userMeData?.instructor?.position !== 'DIRECTOR') {
      return {
        locale: Korean,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "F Y",
          }),
        ],
        minDate: `${lastMonthYear}-${lastMonth}`, // 지난달의 첫날
        maxDate: `${currentYear}-${currentMonth}`, // 이번 달의 마지막 날
        disable: [
          function(date) {
            // minDate와 maxDate 범위 밖의 날짜를 비활성화
            const minDate = new Date(lastMonthYear, lastMonthDate.getMonth()); 
            const maxDate = new Date(currentYear, currentMonth - 1); 
            return date < minDate || date > maxDate;
          }
        ]

      };
    } else {
      return {
        locale: Korean,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "F Y",
          }),
        ],
        disableMobile: "true"
      };
    }
  };

  const wageFormatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
  };

  // 날짜가 minDate와 maxDate 범위 안에 있는지 확인하는 함수
  const isDateInRange = (date) => {
    const minDate = new Date(lastMonthYear, lastMonthDate.getMonth()); // 지난달의 첫날
    const maxDate = new Date(currentYear, currentMonth - 1); // 이번 달의 마지막 날

    return date >= minDate && date <= maxDate;
  };

  const handleDateChange = (date) => {
    const selectedDate = date[0];
    const formattedDate = wageFormatDate(selectedDate);
    
    // 선택된 날짜가 범위 안에 있는지 확인
    if (userMeData?.instructor?.position !== 'DIRECTOR' && !isDateInRange(selectedDate)) {
      
      alert("선택할 수 있는 날짜는 지난달과 이번 달만 가능합니다.");
      return; // 유효하지 않은 날짜일 경우 더 이상 진행하지 않음
    }

    setSearchDate(formattedDate);
  };

  // userMeData가 로드될 때까지 로딩 스피너를 보여주거나 대기 처리
  if (userMeLoading || !userMeData) {
    return <div></div>;  // 로딩 중일 때의 처리 (로딩 스피너 등)
  }

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-2">월별 정산내역</h4>
                { userMeData?.instructor?.position !== 'DIRECTOR' && (
                <h5>
                  <li><code>당월 또는 이전월에 대해서만 조회가 가능합니다.</code></li>
                </h5>
                )}
              </div>
            </Card.Header>
            <Card.Header className="d-flex justify-content-between">
              <div>
                <Flatpickr
                  options={getFlatpickrOptions()}
                  value={searchDate}
                  className="form-control flatpicker"
                  onChange={handleDateChange}
                />
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default InstructorWageMonthSearch;