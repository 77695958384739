import { useState, useEffect } from 'react';
import { getLessonUserList } from '../../api/lesson';

const useLessonUserLists = (storeId, searchDate) => {
  const [lessonList, setLessonList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!storeId) return; // Do nothing if storeId is not set
    
    const fetchData = async () => {
      try {
        const data = await getLessonUserList(storeId, searchDate);
        setLessonList(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeId, searchDate]);

  return { lessonList, loading, error };
};

export default useLessonUserLists;