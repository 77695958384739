import React, { memo, Fragment, useRef, useEffect, useCallback } from "react";

//react-bootstrap
import { Row, Col } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import ReactDOMServer from "react-dom/server";

import { formatDate, formatDate2 } from "../../../utilities/dateFormatter";
import useStoreList from "../../../hooks/useStoreList";
import DeleteIcon from "../../../components/icon/DeleteIcon";
import { deleteStore } from "../../../api/store";

const StoreList = memo(() => {
  const { storeList, loading, error, refresh } = useStoreList();

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    { data: "name", title: "지점명" },
    {
      data: "createdAt",
      title: "등록일",
      render: function (data, type, row) {
        return formatDate2(data);
      },
    },
    {
      data: "updatedAt",
      title: "수정일",
      render: function (data, type, row) {
        return formatDate2(data);
      },
    },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: (data, type, row) => {
        return `
          <div class="flex align-items-center list-user-action">
            <a class="btn btn-sm btn-icon btn-danger delete-btn" data-id="${data.id}" href="#" aria-label="Delete">
              <span class="btn-inner">
                ${ReactDOMServer.renderToString(<DeleteIcon />)}
              </span>
            </a>
          </div>`;
      },
    },
  ]);

  const handleDelete = useCallback(async (id) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (confirmDelete) {
      try {
        await deleteStore(id);
        alert("삭제되었습니다.");
        refresh(); // 데이터 갱신
      } catch (error) {
        alert(error.message);
      }
    }
  }, [refresh]);

  const listtableRef = useRef(null);

  useEffect(() => {
    if (listtableRef.current && storeList) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
      }

      const table = $("#datatable-ecom").DataTable({
        data: storeList,
        columns: columns.current,
        language: {
          emptyTable: "데이터가 없습니다."
        },
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });

      $('#datatable-ecom tbody').on('click', 'a.delete-btn', function (e) {
        e.preventDefault();
        const id = $(this).data('id');
        handleDelete(id);
      });

      return () => {
        table.destroy();
        $('#datatable-ecom tbody').off('click');
      };
    }
  }, [storeList, handleDelete]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: storeList,
  });

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">지점 리스트</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

StoreList.displayName = "StoreList";
export default StoreList;
