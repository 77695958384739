import { useState, useEffect } from 'react';
import { getInstructorWages } from '../api/instructor';

const useInstructorWagesById = (instructorId, searchYear) => {
  const [instructorWageList, setInstructorWageList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (instructorId) {
          const data = await getInstructorWages(instructorId, searchYear);
          setInstructorWageList(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [instructorId, searchYear]);

  return { instructorWageList, loading, error };
};

export default useInstructorWagesById;
