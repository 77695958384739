import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Form, Button, Col } from "react-bootstrap";
import Select from "react-select";

//components
import Card from "../../../components/bootstrap/card";

// api
import { getUserMe } from "../../../api/auth";
import { createSalesReceiveLog } from "../../../api/sales";
import { useNavigate, useParams } from "react-router-dom";
import useFetchSales from "../../../hooks/sales/useFetchSales";
import CommaFormattedInput from "../../../components/form/CommaFormattedInput";

import useFetchInstructorList from "../../../hooks/useFetchInstructorList";
import SelectForm from "../../../components/form/SelectForm";
import salesOptions from "../../../json/salesOptions.json";

const SalesReceiveLogAdd = memo(() => {
  const navigate = useNavigate();
  const { salesId } = useParams();
  const { salesData } = useFetchSales(salesId);
  const [formData, setFormData] = useState({
    salesId: salesId,
    instructorId: "",
    storeId: null,
    saleType: "잔금 납부",
    payMethod: "",
    memo: "",
    issueNumber: "",
    payment: {
      price: 0,
      receivePrice: 0,
      cardPrice: 0,
      bankPrice: 0,
      cashPrice: 0,
      qrPrice: 0,
      voucherPrice: 0,
      discount: 0,
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [receivePrice, setReceivePrice] = useState(0);

  useEffect(() => {
    if (salesData) {
      setReceivePrice(salesData.payment.receivePrice);
    }
  }, [salesData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    const updatedPayment = {
      ...formData.payment,
      [name]: parseFloat(value) || 0, // parseFloat를 사용하여 숫자로 변환
    };

    const totalPaymentPrice = 
      updatedPayment.cardPrice +
      updatedPayment.bankPrice +
      updatedPayment.cashPrice +
      updatedPayment.qrPrice +
      updatedPayment.voucherPrice;

    updatedPayment.price = totalPaymentPrice;
    updatedPayment.receivePrice = receivePrice - totalPaymentPrice;

    setFormData((prevData) => ({
      ...prevData,
      payment: updatedPayment,
    }));
  };

  const [instructorSelectedData, setInstructorSelectedData] = useState(null);
  const handleInstructorSelectedChange = (target) => {
    setInstructorSelectedData(target.value);
  };

  const [userInfoData, setUserInfoData] = useState();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserInfoData(userData);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
  }, []);
  const storeId = userInfoData?.instructor?.storeId;

  // 강사 검색 셀렉트
  const { instructorListOption } = useFetchInstructorList(storeId);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    formData.userId = salesData.userId;
    formData.instructorId = instructorSelectedData;
    formData.storeId= storeId;

    // console.log(formData);
    // setLoading(false);
    // setSuccess(true);
    // return false;

    if (formData.instructorId === null || formData.instructorId === undefined) {
      setLoading(false);
      alert("담당강사를 선택해주세요.");
      return false;
    }

    if (formData.price === 0) {
      setLoading(false);
      alert("처리금액을 입력해주세요.");
      return false;
    }

    if (formData.price > salesData.receivePrice) {
      setLoading(false);
      alert("처리금액이 현재 남은 잔금 금액보다 큽니다.");
      return false;
    }

    try {
      await createSalesReceiveLog(formData);
      setSuccess(true);
      alert("등록되었습니다.");
      navigate("/sales/sales-receive-list");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <div className="col-md-6">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">잔금 처리</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleRegister}
                  disabled={loading}
                >
                  {loading ? "등록중..." : "등록"}
                </Button>{" "}
                <Button
                  type="button"
                  variant="btn btn-info"
                  onClick={() => navigate("/sales/sales-receive-list")}
                >
                  목록
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
      <Row>
        <div className="col-md-6">
          <Card>
            <Card.Body>
              <Form>
                <div className="row">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">현재 남은 잔금</Form.Label>
                    <CommaFormattedInput
                      value={receivePrice}
                      onChange={handlePaymentChange}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <SelectForm
                      label="결제방법"
                      name="payMethod"
                      value={formData.payMethod}
                      selectedOptions={salesOptions.payMethod}
                      handleChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">금액</Form.Label>
                    <CommaFormattedInput
                      name="price"
                      value={formData.payment.price}
                      onChange={handlePaymentChange}
                      disabled
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">
                      <div className="text-danger">납부시 잔금</div>
                    </Form.Label>
                    <CommaFormattedInput
                      name="receivePrice"
                      value={formData.payment.receivePrice}
                      onChange={handlePaymentChange}
                      disabled
                      className="text-danger"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">카드</Form.Label>
                    <CommaFormattedInput
                      name="cardPrice"
                      value={formData.payment.cardPrice}
                      onChange={handlePaymentChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">계좌</Form.Label>
                    <CommaFormattedInput
                      name="bankPrice"
                      value={formData.payment.bankPrice}
                      onChange={handlePaymentChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">현금</Form.Label>
                    <CommaFormattedInput
                      name="cashPrice"
                      value={formData.payment.cashPrice}
                      onChange={handlePaymentChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">QR</Form.Label>
                    <CommaFormattedInput
                      name="qrPrice"
                      value={formData.payment.qrPrice}
                      onChange={handlePaymentChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">바우처</Form.Label>
                    <CommaFormattedInput
                      name="voucherPrice"
                      value={formData.payment.voucherPrice}
                      onChange={handlePaymentChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-md-6">
                    <Form.Label htmlFor="lname">현/영 발급번호</Form.Label>
                    <Form.Control
                      type="text"
                      name="issueNumber"
                      value={formData.issueNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="userList">담당 강사</Form.Label>
                    <Select
                      onChange={handleInstructorSelectedChange}
                      options={instructorListOption}
                      placeholder="선택해주세요."
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="add2">메모</Form.Label>
                    <Form.Control
                      type="text"
                      name="memo"
                      value={formData.memo}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Fragment>
  );
});

SalesReceiveLogAdd.displayName = "SalesReceiveLogAdd";
export default SalesReceiveLogAdd;
