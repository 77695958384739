import { Button, Form } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import { Link } from "react-router-dom";
import SelectForm from "../../../../components/form/SelectForm";
import userOpttions from "../../../../json/userOptions.json";

const UserEditLeftForm = ({ formData, contractView, handleChange, handleUpdate, handleContractImageUpload }) => (
  <div className="col-md-6">
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">개인 정보</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="new-user-info">
          <Form onSubmit={handleUpdate}>
            <div className="row">
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="fname">이름</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="lname">이메일 (아이디)</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} disabled />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="add1">연락처</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="add2">생년월일</Form.Label>
                <Form.Control
                  type="text"
                  name="birth"
                  value={formData.birth}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="col-md-12  form-group">
                <SelectForm
                  label="등록경로"
                  name="joinPath"
                  value={formData.joinPath}
                  selectedOptions={userOpttions.joinPath}
                  handleChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="custom-file-input">계약서 이미지 첨부</Form.Label>
                <Form.Control type="file" name="contractImage" onChange={handleContractImageUpload} />
              </Form.Group>
              {contractView !== null && (
                <Link to={contractView} target="_blank" className="text-success mb-3">
                  현재 계약서 확인
                </Link>
              )}
              {" "}
              {(formData.contractImage !== null && formData.contractImage !== contractView) && (
                <Link to={formData.contractImage} target="_blank" className="text-danger mb-3">
                  등록된 계약서 확인
                </Link>
              )}
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="add2">메모</Form.Label>
                <Form.Control
                  as="textarea"
                  name="memo"
                  value={formData.memo || ""}
                  onChange={handleChange}
                  rows="5"
                />
              </Form.Group>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default UserEditLeftForm;
