import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Form, Table, Button, Modal } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";
import { createLeaveLog, getLeaveDetail } from "../../../api/leave";
import { useNavigate } from "react-router-dom";

//flatpicker
import Flatpickr from "react-flatpickr";
import { getUserMe } from "../../../api/auth";
import { leaveTypeDetailMapping } from "../../../mapping/leaveMapping";

const LeaveUse = memo(() => {
  const [useLeaveLogData, setUseLeaveLogData] = useState({
    instructorId: "",
    useDay: "",
    leaveType: "",
    description: "",
  });
	const [dateRange, setDateRange] = useState({
    leaveStartDate: null,
    leaveEndDate: null,
  });

  const year = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

	const [userInfoData, setUserInfoData] = useState();
  const [leaveDetailData, setLeaveDetailData] = useState({
    annualLeaveDays: 0,
    annualLeaveUseDays: 0,
    halfDays: 0,
    halfUseDays: 0,
    quarterDays: 0,
    quarterUseDays: 0,
  });

	const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserMe();
        setUserInfoData(userData);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };
  
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userInfoData && userInfoData.instructor) {
      const fetchLeaveDetail = async () => {
        try {
          const leaveDetail = await getLeaveDetail(userInfoData.instructor.id, year);
          setLeaveDetailData(leaveDetail);
        } catch (error) {
          console.error("Failed to fetch leave detail", error);
        }
      };
  
      fetchLeaveDetail();
    }
  }, [userInfoData, year]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUseLeaveLogData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

	// Handler for date range selection
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setDateRange({
        leaveStartDate: start,
        leaveEndDate: end,
      });
    }
  };

  const handleLeaveUse = async (e) => {
    e.preventDefault();
    
    const leaveInfo = leaveTypeDetailMapping[useLeaveLogData.leaveType];
    if (!leaveInfo) {
      throw new Error(`Invalid leave type: ${useLeaveLogData.leaveType}`);
    }
    const remainDay = leaveDetailData[leaveInfo.totalDays] - leaveDetailData[leaveInfo.usedDays];
    
		if (dateRange.leaveStartDate && dateRange.leaveEndDate) {
      const startDateTimestamp = dateRange.leaveStartDate.getTime(); // Convert to Unix timestamp
      const endDateTimestamp = dateRange.leaveEndDate.getTime(); // Convert to Unix timestamp
      const differenceInMilliseconds = endDateTimestamp - startDateTimestamp;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
      const dayCount = Math.floor(differenceInDays);

      if (useLeaveLogData.leaveType !== 'ANNUAL' && dayCount !== 0) {
        alert('반휴강 또는 반반휴강은 날짜 선택시 하루단위만 가능합니다.');
        return false;
      }

			if (dayCount > remainDay) {
				alert('잔여 휴강이 부족합니다.');
				return false;
			}
    } else {
			alert('날짜를 입력해주세요.');
			return false;
		}

		useLeaveLogData.instructorId = userInfoData.instructor.id;
    try {
      await createLeaveLog(useLeaveLogData, dateRange);
			alert('등록되었습니다.');
			navigate('/manage/leave-list')
      console.log("createdData", useLeaveLogData);
    } catch (error) {
      console.error("Error deleting leave log:", error.message);
    }
  };

  const handleLeaveList = () => {
    navigate('/manage/leave-list'); // Navigate to the previous page in the history stack
  };

  return (
    <Fragment>
      <Row>
        <Col lg="6">
          <Card className="rounded">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">휴강 신청</h4>
              </div>
              <div>
                <h6 className="mb-1">잔여 휴강: {leaveDetailData.annualLeaveDays - leaveDetailData.annualLeaveUseDays}</h6>
                <h6 className="mb-1">잔여 반휴강: {leaveDetailData.halfDays - leaveDetailData.halfUseDays}</h6>
                <h6 className="mb-1">잔여 반반휴강: {leaveDetailData.quarterDays - leaveDetailData.quarterUseDays}</h6>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <div className="table-responsive-lg">
                    <Form onSubmit={handleLeaveUse}>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label>날짜</Form.Label>
                        <div className="form-group">
                          <Flatpickr
                            options={{ mode: "range", minDate: "today" }}
                            className="form-control flatpickrdate"
                            placeholder="날짜를 선택해주세요."
														onChange={handleDateChange}
														required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="useDaySelect">종류</Form.Label>
                        <select
                          className="form-select"
                          name="leaveType"
                          value={useLeaveLogData.leaveType}
                          onChange={handleChange}
                          required
                        >
                          <option value="">선택해주세요.</option>
                          <option value="ANNUAL">휴강</option>
                          <option value="HALF">반휴강</option>
                          <option value="QUARTER">반반휴강</option>
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group mb-5">
                        <Form.Label htmlFor="description">메모</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          value={useLeaveLogData.description}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Button type="submit" variant="btn btn-primary" disabled={loading}>
                      {loading ? 'Registering...' : '신청'}
                      </Button>
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      {success && <p style={{ color: 'green' }}>Registration successful!</p>}
											{" "}
                      <Button
												type="button"
												variant="btn btn-light"
												onClick={handleLeaveList}
											>
												휴강 현황
											</Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

LeaveUse.displayName = "LeaveUse";
export default LeaveUse;
