import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../../../components/bootstrap/card";
import CommaFormattedInput from "../../../../../components/form/CommaFormattedInput";

const InstructorWageGroupForm = ({ formData, groupLessonDiscountPrice, handleLessnDataChange }) => (
  <Card>
    <Card.Header className="d-flex justify-content-between">
      <div className="header-title">
        <h4 className="card-title">그룹레슨</h4>
      </div>
    </Card.Header>
    <Card.Body>
      <div className="new-user-info">
        <div className="row">
          <Form.Group className="col-md-12 form-group">
            <Form.Label htmlFor="fname">금액 합계</Form.Label>
            <CommaFormattedInput
              name="groupPrice"
              value={formData.groupPrice}
              onChange={handleLessnDataChange}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="col-md-6 form-group">
            <Form.Label htmlFor="fname">참여인원</Form.Label>
            <Form.Control
              type="number"
              name="groupCount"
              value={formData.groupCount}
              onChange={handleLessnDataChange}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="col-md-6 form-group">
            <Form.Label htmlFor="fname">운용레슨수</Form.Label>
            <Form.Control
              type="number"
              name="groupLessonCount"
              value={formData.groupLessonCount}
              onChange={handleLessnDataChange}
              disabled={true}
              required
            />
          </Form.Group>
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default InstructorWageGroupForm;
