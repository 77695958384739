import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Table, Button, Modal, Form } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";
import ListButton from "../../../components/button/ListButton";
import { getLeaveLog, updateLeaveLogStatus } from "../../../api/leave";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../utilities/dateFormatter";
import { leaveTypeMapping } from "../../../mapping/leaveMapping";
import useInstructorInfo from "../../../hooks/useInstructorInfo";
import EditIcon from "../../../components/icon/EditIcon";

const LeaveRequest = memo(() => {
  const [refreshData, setRefreshData] = useState(false);
  const { instructorId } = useParams();

  // 강사 정보 조회
  const { instructorData, refresh } = useInstructorInfo(instructorId);

  const [leaveLogs, setLeaveLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [leaveLog, setLeaveLog] = useState(null);
  const handleRequestDoneModalClose = () => setShowModal(false);
  const handleRequestDoneModalShow = (data) => {
    setShowModal(true);
    setLeaveLog(data);
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const details = await getLeaveLog(instructorId, 'REQUEST');
        const formattedDetails = details.map((detail) => ({
          ...detail,
          date: formatDate(detail.createdAt),
        }));
        setLeaveLogs(formattedDetails);
      } catch (error) {
        console.error("Failed to load leave details", error);
      }
    };
    fetchDetails();
  }, [instructorId, refreshData]);

  const handleRequestDone = async (doneStatus) => {
    try {
      const formData = {
        logId: leaveLog.id,
        status: doneStatus
      }
      await updateLeaveLogStatus(formData);
      setRefreshData((prev) => !prev); // Toggle refreshData to trigger useEffect
      alert('반영되었습니다.');
      handleRequestDoneModalClose();
    } catch (error) {
      console.error("Error deleting leave log:", error.message);
    }
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page in the history stack
  };

  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Card className="rounded">
            <Card.Body>
              <Row>
                <Col sm="12">
                  {/* <h4 className=" mb-2 border-bottom pb-2">{instructorData.user && instructorData.user.name}</h4> */}
                  <h5 className="mb-3">{instructorData && instructorData?.name}님 휴강 신청내역</h5>
                  <Button type="button" variant="btn btn-light" onClick={handleBackClick}>
                    뒤로가기
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className=" mt-4">
                  <div className="table-responsive-lg">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">일자</th>
                          <th className="text-center" scope="col">
                            갯수
                          </th>
                          <th className="text-center" scope="col">
                            구분
                          </th>
                          <th className="text-center" scope="col">
                            사유
                          </th>
                          <th className="text-center" scope="col">
                            처리
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveLogs.length === 0 ? (
                          <tr>
                            <td colSpan="5" className="text-center">
                              내역이 없습니다
                            </td>
                          </tr>
                        ) : (
                          leaveLogs.map((detail, index) => (
                            <tr key={index}>
                              <td>
                                <h6 className="mb-0">
                                  {detail.leaveStartDate} ~ {detail.leaveEndDate}
                                </h6>
                              </td>
                              <td className="text-center">{detail.useDay}</td>
                              <td className="text-center">{leaveTypeMapping[detail.leaveType]}</td>
                              <td className="text-center">{detail.memo}</td>
                              <td className="text-center">
                                <ListButton
                                  onClick={() => handleRequestDoneModalShow(detail)}
                                  className="btn-secondary"
                                >
                                  <EditIcon />
                                </ListButton>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <Modal
                    show={showModal}
                    onHide={handleRequestDoneModalClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>휴강 승인</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      휴강 승인시 사용한 휴강 갯수가 차감됩니다.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="success"
                        onClick={() => handleRequestDone('APPROVE')}
                      >
                        승인
                      </Button>{" "}
                      <Button
                        variant="danger"
                        onClick={() => handleRequestDone('REJECT')}
                      >
                        반려
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

LeaveRequest.displayName = "LeaveRequest";
export default LeaveRequest;
