import React from 'react';
import { Form } from 'react-bootstrap';

const CheckboxGroup = ({ label, options, fieldName, selectedOptions, handleCheckboxChange }) => {
  return (
    <Form.Group className="form-group">
      <Form.Label>{label}</Form.Label>
      <div className="d-flex flex-wrap">
        {options.map((option, index) => (
          <Form.Check key={index} className="d-flex align-items-center me-3 mb-2">
            <Form.Check.Input
              type="checkbox"
              id={`${fieldName}-checkbox-${index}`}
              checked={selectedOptions?.includes(option) || false}
              onChange={() => handleCheckboxChange(option, fieldName)}
            />
            <Form.Check.Label htmlFor={`${fieldName}-checkbox-${index}`} className="ms-2">
              {option}
            </Form.Check.Label>
          </Form.Check>
        ))}
      </div>
    </Form.Group>
  );
};

export default CheckboxGroup;
