import React, { memo, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
//react-bootstrap
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { createStore } from "../../../api/store";

const StoreAdd = memo(() => {
  const [storeData, setStoreData] = useState({
    name: "",
    status: true,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setStoreData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleScheduleAdd = async (e) => {
    e.preventDefault();

    try {
      await createStore(storeData);
      alert('등록되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg="6">
          <Card className="rounded">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">신규지점 등록</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <div className="table-responsive-lg">
                    <Form onSubmit={handleScheduleAdd}>
                      
                      <Form.Group className="col-md-12 form-group">
                        <Form.Label htmlFor="count">지점명</Form.Label>
                        <Form.Control
                          name="name"
                          value={storeData.name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Button type="submit" variant="btn btn-primary" disabled={loading}>
                        {loading ? "Registering..." : "등록"}
                      </Button>{" "}
                      <Button type="button" variant="btn btn-light" onClick={() => navigate("/store/store-list")}>
                        지점 리스트
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

StoreAdd.displayName = "StoreAdd";
export default StoreAdd;