import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Button, Col, Form, Row } from "react-bootstrap";

//components
import { getUserInfo, getUserSurvey, updateUserSurvey } from "../../../api/auth";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../components/bootstrap/card";
import UserSurveyLeftForm from "./component/UserSurveyLeftForm";
import UserSurveyRightForm from "./component/UserSurveyRightForm";
import options from "../../../json/options.json";

const UserSurvey = memo(() => {
  const { userId } = useParams();
  const [formData, setFormData] = useState({
    joinPath: [],
    experience: "",
    interestProgram: [],
    selectReason: [],
    exercisePurpose: [],
    preferExercise: [],
    preferDay: [],
    preferTime: [],
    height: 0,
    weight: 0,
    recentWeightChange: "",
    skinDisease: "",
    drinking: "",
    smoking: "",
    diseaseDetails: [],
    geneticDisease: "",
    surgicalHistory: "",
    inspectionDetails: "",
    periodState: "",
    pregnancyMonth: "",
    childbirthDate: "",
    pregnancyMemo: "",
    childbirthCount: "",
    childbirthMemo: "",
  });
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    birth: "",
  })

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/user/user-list"); // Navigate to the previous page in the history stack
  };

  useEffect(() => {
    const fetchUserSurvey = async () => {
      setLoading(true);
      try {
        const survey = await getUserSurvey(userId);
        if (survey) {
          setFormData(survey);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserSurvey();
  }, [userId]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      try {
        const userInfo = await getUserInfo(userId);
        setUserData(userInfo);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await updateUserSurvey(userId, formData);
      alert('저장되었습니다.');
      setSuccess(true);
    } catch (error) {
      alert(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (option, group) => {
    setFormData((prevFormData) => {
      const isSelected = prevFormData[group]?.includes(option) || false;
      const newGroupValues = isSelected
        ? prevFormData[group].filter((item) => item !== option)
        : [...(prevFormData[group] || []), option];

      return { ...prevFormData, [group]: newGroupValues };
    });
  };

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">"{userData.name}"님 개인레슨 문진표</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  {loading ? "저장" : "저장"}
                </Button>{" "}
                {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                {/* {success && <p style={{ color: "green" }}>Registration successful!</p>} */}
                <Button type="button" variant="btn btn-light" onClick={handleBackClick}>
                  뒤로가기
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <UserSurveyLeftForm
          userData={userData}
          formData={formData}
          handleChange={handleChange}
          options={options}
          handleCheckboxChange={handleCheckboxChange}
        />
        <UserSurveyRightForm
          formData={formData}
          handleChange={handleChange}
          options={options}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Row>
    </Fragment>
  );
});

UserSurvey.displayName = "UserSurvey";
export default UserSurvey;
