import { useState, useEffect } from "react";
import { getUserAnalysis } from "../api/auth";

const useUserAnalysisList = (storeId, searchType, searchProgram) => {
  const [userAnalysisData, setUserAnalysisData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const searchDate = `${currentYear}-${currentMonth}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // if (searchDate.length > 4) {
          const data = await getUserAnalysis(storeId, searchType, searchProgram);
          setUserAnalysisData(data);
        // }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeId, searchType, searchProgram]);

  return { userAnalysisData, loading, error };
};

export default useUserAnalysisList;
