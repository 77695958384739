import $ from 'jquery';
import 'datatables.net';

// DataTables 기본 설정을 전역적으로 적용
$.extend(true, $.fn.dataTable.defaults, {
  language: {
    emptyTable: "데이터가 없습니다.",
    info: "_START_ - _END_ (총 _TOTAL_ 건)",
    infoEmpty: "0건",
    infoFiltered: "(전체 _MAX_ 건 중 검색결과)",
    lengthMenu: "_MENU_ 개씩 보기",
    loadingRecords: "로딩중...",
    processing: "처리중...",
    search: "검색:",
    zeroRecords: "검색 결과가 없습니다.",
    paginate: {
      first: "처음",
      last: "마지막",
      next: "다음",
      previous: "이전"
    },
    aria: {
      sortAscending: ": 오름차순 정렬",
      sortDescending: ": 내림차순 정렬"
    }
  }
});
