import { useState, useEffect, useCallback } from "react";
import { getLessonList } from "../../api/lesson";

const useLessonLists = (storeId, searchDate, allView, selectInstructorId, groupLessonView) => {
  const [lessonList, setLessonList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      if (storeId && selectInstructorId != null) {
        if (allView) {
          const data = await getLessonList(storeId, searchDate, null, groupLessonView);
          setLessonList(data);
        } else {
          const data = await getLessonList(storeId, searchDate, selectInstructorId, groupLessonView);
          setLessonList(data);
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [storeId, searchDate, allView, selectInstructorId, groupLessonView])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { lessonList, loading, error, refresh: fetchData };
};

export default useLessonLists;
