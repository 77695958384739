import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getLeaveLists = async (storeId, year) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/leave/lists`, {
      params: { storeId, year }
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching leave lists:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch leave lists"
    );
  }
};

export const getLeaveDetailById = async (id) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/leave/detail/${id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching leave detail:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch leave detail"
    );
  }
};

export const getLeaveDetail = async (instructorId, year) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/leave/detail`, {
      params: { instructorId, year }
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching leave detail:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch leave detail"
    );
  }
};

export const getLeaveLog = async (instructorId, logStatus) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/leave/log`, {
      params: { instructorId, logStatus }
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching leave:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch leave"
    );
  }
};

export const deleteLeaveLog = async (id) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/leave/${id}`)
    return response.data
  } catch (error) {
    console.error(
      "Error fetching leave log delete:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch leave delete"
    );
  }
};

export const createLeaveLog = async (useLeaveLogData, dateRange) => {
  const formData = {
    instructorId: useLeaveLogData.instructorId,
    description: useLeaveLogData.description,
    leaveStartDate: dateRange.leaveStartDate,
    leaveEndDate: dateRange.leaveEndDate,
    leaveType: useLeaveLogData.leaveType
  }

  try {
    const response = await apiClient.post(`${API_URL}/admin/leave/request`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "createLeaveLog failed");
  }
};

export const updateLeaveLog = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/leave/update`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateLeaveLog failed");
  }
};

export const updateLeaveLogStatus = async (formData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/leave/log/update`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateLeaveLogStatus failed");
  }
}

export const getLeaveUpdateLog = async (instructorId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/leave/update_log`, {
      params: { instructorId }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to getLeaveUpdateLog");
  }
};