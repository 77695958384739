import { useState, useEffect, useCallback } from 'react';
import { getConfigLessonList } from '../../api/config';

const useConfigLessonList = (storeId) => {
  const [lessonList, setLessonList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (storeId) {
        const data = await getConfigLessonList(storeId);
        setLessonList(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [storeId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { lessonList, loading, error, refresh: fetchData };
};

export default useConfigLessonList;
