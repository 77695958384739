import { Button, Form } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import RadioButton from "../../../../components/button/RadioButton";
import CheckboxGroup from "../../../../components/form/CheckboxGroup";

const UserSurveyLeftForm = ({
  userData,
  formData,
  handleChange,
  options,
  handleCheckboxChange,
}) => (
  <div className="col-md-6">
    <Card>
      <Card.Body>
        <div className="new-user-info">
          <Form>
            <div className="row">
              <Form.Group className="col-md-12 form-group">
                <Form.Label>이름</Form.Label>
                <Form.Control type="text" value={userData.name} disabled />
              </Form.Group>
              <RadioButton
                label="성별"
                options={options.gender}
                fieldName="gender"
                selectedValue={formData.gender}
                handleChange={handleChange}
              />
              <Form.Group className="col-md-12 form-group">
                <Form.Label>생년월일</Form.Label>
                <Form.Control type="text" value={userData.birth} disabled />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label>연락처</Form.Label>
                <Form.Control type="text" value={userData.phone} disabled />
              </Form.Group>
              <Form.Group className="col-md-12 form-group">
                <Form.Label htmlFor="add2">메모</Form.Label>
                <Form.Control
                  as="textarea"
                  name="memo"
                  value={formData.memo}
                  onChange={handleChange}
                  rows="5"
                />
              </Form.Group>
              <CheckboxGroup
                label="유입경로"
                options={options.joinPath}
                fieldName="joinPath"
                selectedOptions={formData.joinPath}
                handleCheckboxChange={handleCheckboxChange}
              />
              <RadioButton
                label="필라테스 경험"
                options={options.radioDefaultValue}
                fieldName="experience"
                selectedValue={formData.experience}
                handleChange={handleChange}
              />
              <CheckboxGroup
                label="관심프로그램"
                options={options.interestProgram}
                fieldName="interestProgram"
                selectedOptions={formData.interestProgram}
                handleCheckboxChange={handleCheckboxChange}
              />
              <CheckboxGroup
                label="메디앤컬 선택이유"
                options={options.selectReason}
                fieldName="selectReason"
                selectedOptions={formData.selectReason}
                handleCheckboxChange={handleCheckboxChange}
              />
              <CheckboxGroup
                label="운동목적"
                options={options.exercisePurpose}
                fieldName="exercisePurpose"
                selectedOptions={formData.exercisePurpose}
                handleCheckboxChange={handleCheckboxChange}
              />
              <CheckboxGroup
                label="선호하는 운동스타일"
                options={options.preferExercise}
                fieldName="preferExercise"
                selectedOptions={formData.preferExercise}
                handleCheckboxChange={handleCheckboxChange}
              />
              <CheckboxGroup
                label="선호하는 운동요일"
                options={options.preferDay}
                fieldName="preferDay"
                selectedOptions={formData.preferDay}
                handleCheckboxChange={handleCheckboxChange}
              />
              <CheckboxGroup
                label="선호하는 운동시간대"
                options={options.preferTime}
                fieldName="preferTime"
                selectedOptions={formData.preferTime}
                handleCheckboxChange={handleCheckboxChange}
              />
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default UserSurveyLeftForm;
