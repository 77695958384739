import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem('refreshToken', action.payload);
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
      localStorage.removeItem('accessToken');
    },
    clearRefreshToken: (state) => {
      state.refreshToken = null;
      localStorage.removeItem('refreshToken');
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  clearAccessToken,
  clearRefreshToken,
} = authSlice.actions;

export default authSlice.reducer;
