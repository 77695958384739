export const saleTypeMapping = {
  INITIAL: "신규등록",
  RENEWAL: "재등록",
  INTRODUCE: "소개",
  PROGRAM_CHANGE: "프로그램 변경",
  TRANSFER: "양도",
};

export const classificationMapping = {
  INDIVIDUAL: "개인",
  GROUP: "그룹",
  MIX: "믹스",
  LEADER: "지도자",
  OUTSIDE: "외부",
};

export const paymentMethodMapping = {
  CARD: "카드",
  BANK: "계좌",
  CASH: "현금"
}

export const lessonUseStatusMapping = {
  READY: "대기",
  USING: "이용중",
  COMPLETED: "이용완료"
}