import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const login = async (email, password) => {
  try {
    const response = await apiClient.post(`${API_URL}/user/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Login failed");
  }
};

export const getUserLists = async (storeId, startDate, endDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/user/lists`, {
      params: { storeId, startDate, endDate }
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching user lists:",
      error.response?.data || error.message
    ); // Debugging log
    throw new Error(
      error.response?.data?.message || "Failed to fetch user lists"
    );
  }
};

export const userRegister = async (userData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/user/create`, userData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Registration failed");
  }
};

export const getUserInfo = async (userId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/user/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getUserInfo failed");
  }
};

export const getUserMe = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/user/me`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getUserMe Response Failed");
  }
}

export const updateUser = async (userId, userData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/user/update/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateUser failed");
  }
}

export const deleteUser = async (userId) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/user/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "deleteUser failed");
  }
}

export const updateUserPassword = async (userId, password) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/user/update/${userId}/password`, {
      password: password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateUserPassword failed");
  }
};

export const getUserAnalysis = async (storeId, searchType, programId) => {
  try {
    if (storeId) {
      const response = await apiClient.get(`${API_URL}/admin/user/analysis`, {
        params: { storeId, searchType, programId }
      });
      return response.data;
    }
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to getUserAnalysis"
    );
  }
}

export const getUserSurvey = async (userId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/user/survey/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to getUserSurvey"
    );
  }
}

export const updateUserSurvey = async (userId, formData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/user/survey/${userId}`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to updateUserSurvey");
  }
}

export const uploadContractImage = async (file, storeId, userId) => {
  try {
    const formData = new FormData();
    formData.append("contractImage", file);
    const response = await apiClient.post(`${API_URL}/admin/user/contract`, formData, {
      params: { storeId, userId },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data.url;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to uploadContractImage");
  }
}

export const getUserProgramLogs = async (userId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/user/program/logs`, {
      params: { userId }
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch getUserProgramLogs"
    );
  }
}