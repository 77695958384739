import { useState, useEffect, useCallback } from 'react';
import { getUserLists } from '../api/auth';

const useUserLists = (storeId, startDate, endDate) => {
  const [userLists, setUserLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (storeId) {
        const data = await getUserLists(storeId, startDate, endDate);
        setUserLists(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [storeId, startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { userLists, loading, error, refresh: fetchData };
};

export default useUserLists;
