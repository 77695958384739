import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import useUserMe from "../../../hooks/useUserMe";
import { getConfigLesson, updateConfigLesson } from "../../../api/config";
import SelectForm from "../../../components/form/SelectForm";
import lessonOptions from "../../../json/lessonOptions.json";

const { memo, useState, useEffect, Fragment } = require("react");

const ConfigLessonEdit = memo(() => {
  const { id } = useParams();
  const { userMeData } = useUserMe();
  const store = userMeData?.store;

  const [formData, setFormData] = useState({
    lessonType: "",
    lessonOption: "",
    price: "",
    optionPrice: null,
    storeId: null,
  });

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const result = await getConfigLesson(id);
        setFormData(result);
      } catch (error) {
        console.error("Failed to fetchConfigData", error);
      }
    };
    fetchConfigData();
  }, [id]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/config/lesson");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "lessonType" && value === "INDIVIDUAL") {
      formData.optionPrice = "";
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    if (formData.optionPrice === "") {
      formData.optionPrice = null;
    }
    formData.storeId = store.id;

    // console.log(formData);
    // setLoading(false);
    // return false;

    try {
      await updateConfigLesson(formData);
      alert("수정되었습니다.");
      setSuccess(true);
      navigate("/config/lesson");
    } catch (error) {
      alert(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg="6">
          <Card className="rounded">
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">레슨금액 수정</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12" className=" mt-4">
                  <div className="table-responsive-lg">
                    <Form>
                      <Form.Group className="col-md-12 form-group">
                        <SelectForm
                          label="레슨 종류"
                          name="lessonType"
                          value={formData.lessonType}
                          selectedOptions={lessonOptions.lessonType}
                          handleChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">레슨 옵션</Form.Label>
                        <Form.Control
                          type="text"
                          name="lessonOption"
                          value={formData.lessonOption}
                          onChange={handleChange}
                          placeholder="예시) MOTC, MSIC"
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">금액</Form.Label>
                        <Form.Control
                          type="text"
                          name="price"
                          value={formData.price}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      {formData && formData.lessonType === "GROUP" && (
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="lname">
                            옵션금액 (❗️그룹레슨에서 5인이상 참여시 정산에 사용되는 금액){" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="optionPrice"
                            value={formData.optionPrice}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      )}
                      <hr/>
                      <Button
                        type="button"
                        variant="btn btn-primary"
                        onClick={handleRegister}
                        disabled={loading}
                      >
                        수정
                      </Button>{" "}
                      <Button
                        type="button"
                        variant="btn btn-light"
                        onClick={handleBackClick}
                        disabled={loading}
                      >
                        목록
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

export default ConfigLessonEdit;
