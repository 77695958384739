import React, { memo, Fragment, useState, useEffect, useRef } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import { useParams } from "react-router-dom";
import useFetchSales from "../../../hooks/sales/useFetchSales";
import useUserMe from "../../../hooks/useUserMe";
import useFetchUserList from "../../../hooks/useFetchUserList";
import useFetchInstructorList from "../../../hooks/useFetchInstructorList";
import SalesEditForm from "./component/SalesEditForm";
import { updateSales } from "../../../api/sales";
import useSalesReceiveLog from "../../../hooks/sales/useSalesReceiveLog";
import { formatDate, } from "../../../utilities/dateFormatter";
import { formatNumber } from "../../../utilities/formatNumber";
import { paymentMethodMapping } from "../../../mapping/salesMapping";
import salesOptions from "../../../json/salesOptions.json";
import { filterData } from "../../../utilities/filterData";
import { handleDefaultChange, handleSalesChangeProgram, handleSalesPaymentChange, handleSalesProgram } from "./utils/salesUtils";
import useConfigProgramList from "../../../hooks/config/useConfigProgramList";
import { useLessonData } from "../../../hooks/lesson/useLessonData";

const SalesEdit = memo(() => {
  const { salesId } = useParams();
  const { salesData, loading: salesLoading, error: salesError } = useFetchSales(salesId);
  const { userMeData, error: userInfoError } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;

  const initialFormData = useRef({
    id: salesId,
    userId: null,
    instructorId: "",
    storeId: null,
    programId: "",
    changeProgramId: "",
    saleType: "",
    payMethod: "",
    individualCount: 0,
    individualType: "",
    groupCount: 0,
    isWeekGroup: false,
    memo: "",
    lessonUseStatus: null,
    isStartLesson: false,
    issueNumber: "",
    isTrial: false,
    payment: {
      price: 0,
      receivePrice: 0,
      cardPrice: 0,
      bankPrice: 0,
      cashPrice: 0,
      qrPrice: 0,
      voucherPrice: 0,
      discount: 0,
      refundPrice: 0,
    },
    isReceiveSales: false,
  });

  const [formData, setFormData] = useState(initialFormData.current);

  useEffect(() => {
    if (salesData) {
      const filteredData = filterData(salesData, initialFormData.current);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...filteredData,
      }));
    }
  }, [salesData]);  // No need to include formData in the dependency array

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    handleDefaultChange(e, formData, setFormData);
  };

  const handleProgram = (e) => {
    handleSalesProgram(e, formData, setFormData);
  };

  const handleChangeProgram = (e) => {
    handleSalesChangeProgram(e, formData, setFormData);
  };

  const handlePaymentChange = (e) => {
    handleSalesPaymentChange(e, formData, setFormData);
  };

  const [userSelectedData, setUserSelectedData] = useState(null);
  const handleUserSelectedChange = (target) => {
    setUserSelectedData(target);
  };

  const [instructorSelectedData, setInstructorSelectedData] = useState(null);
  const handleInstructorSelectedChange = (target) => {
    setInstructorSelectedData(target);
  };

  // 클래스 (프로그램) 리스트
  const { programList } = useConfigProgramList(storeId);
  // 레슨 종류 및 옵션 config 연결
  const { lessonTypes, lessonOptions } = useLessonData(userMeData?.instructor.storeId);
  // 회원 검색 셀렉트
  const { userListOption, error: userListError } = useFetchUserList(storeId);
  // 강사 검색 셀렉트
  const { instructorListOption, error: instructorError } = useFetchInstructorList(storeId);
  // 잔금 내역
  const { salesReceiveLog } = useSalesReceiveLog(salesId);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    if (userSelectedData != null) {
      formData.userId = userSelectedData.value;
    }
    if (instructorSelectedData != null) {
      formData.instructorId = instructorSelectedData.value;
    }
    if (formData.lessonUseStatus === "") {
      alert("레슨 이용상태를 선택해주세요.");
      return false;
    }

    // console.log(formData);
    // setLoading(false);
    // setSuccess(true);
    // return false;

    try {
      console.log(formData);
      await updateSales(formData);
      setSuccess(true);
      alert("수정되었습니다.");
      window.location.reload();
    } catch (error) {
      alert(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <div className="col-md-12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="card-title mb-0">
                <h4 className="mb-0">매입/매출 수정페이지</h4>
              </div>
              <div>
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={handleRegister}
                  disabled={loading}
                >
                  {loading ? "수정" : "수정"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Row>
      {!salesLoading && (
        <>
          <SalesEditForm
            formData={formData}
            salesOptions={salesOptions}
            handleChange={handleChange}
            handlePaymentChange={handlePaymentChange}
            handleInstructorSelectedChange={handleInstructorSelectedChange}
            handleUserSelectedChange={handleUserSelectedChange}
            handleProgram={handleProgram}
            handleChangeProgram={handleChangeProgram}
            instructorListOption={instructorListOption}
            userListOption={userListOption}
            programList={programList}
            lessonOptions={lessonOptions}
            selectInstructor={instructorSelectedData}
            selectUser={userSelectedData}
          />
          {formData && formData.isReceiveSales === false && (
          <Row>
            <Col>
              <Card>
                <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                  <div className="card-title mb-0">
                    <h4 className="mb-0">잔금 결제 내역</h4>
                  </div>
                  <hr />
                  <Table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">금액</th>
                        {/* <th scope="col">결제수단</th> */}
                        <th scope="col">결제일</th>
                        <th scope="col">메모</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesReceiveLog.map((row, index) => (
                        <tr key={`${row.id}`}>
                          <th scope="row">{index + 1}</th>
                          <td>{formatNumber(row.price)}</td>
                          {/* <td>{paymentMethodMapping[row.paymentMethod] || row.paymentMethod}</td> */}
                          <td>{formatDate(row.createdAt)}</td>
                          <td>{row.memo}</td>
                        </tr>
                      ))}
                      {salesReceiveLog.length === 0 && (
                        <tr>
                          <td colSpan="4" className="text-center">
                            내역이 없습니다
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          )}
        </>
      )}
    </Fragment>
  );
});

SalesEdit.displayName = "SalesEdit";
export default SalesEdit;
