import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const exportToExcel = (data, fileName, headers) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the dataTable rows to a 2D array with headers
  const modifiedData = [headers, ...data.map(row => Object.values(row))];

  // Convert the modified data to a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(modifiedData);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate a buffer
  const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob from the buffer
  const blob = new Blob([buffer], { type: 'application/octet-stream' });

  // Use FileSaver to save the Blob as an Excel file
  saveAs(blob, `${fileName}.xlsx`);
};

export default exportToExcel