import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getDashboardIndexData = async (storeId) => {
  try {
    if (storeId) {
      const response = await apiClient.get(`${API_URL}/admin/dashboard/index`, {
        params: { storeId },
      });
      return response.data;
    }
  } catch (error) {
    console.error("Error getDashboardIndexData: ", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to getDashboardIndexData");
  }
};