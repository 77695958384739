import React, { memo, Fragment, useRef, useState, useEffect, useMemo } from "react";

//react-bootstrap
import { Row, Col, Form } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import ReactDOMServer from "react-dom/server";

import EditIcon from "../../../components/icon/EditIcon";
import ListButton from "../../../components/button/ListButton";
import useLeaveLists from "../../../hooks/useLeave";
import useUserMe from "../../../hooks/useUserMe";

const LeaveList = memo(() => {
  const { userMeData } = useUserMe();
  const storeId = userMeData?.instructor?.storeId;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const { leaveLists, loading, error } = useLeaveLists(storeId, year);

  const columns = useMemo(() => [
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    {
      data: null,
      title: "이름",
      render: function (data, type, row) {
        let url = `/manage/leave-detail/${data.id}?instructorId=${data.instructorId}`;
        return `<a href="${url}">${data.user.name}</a>`;
      },
    },
    {
      data: null,
      title: "휴강",
      render: function (data, type, row) {
        return `${data.annualLeaveUseDays} / ${data.annualLeaveDays}`;
      },
    },
    {
      data: null,
      title: "반휴강",
      render: function (data, type, row) {
        return `${data.halfUseDays} / ${data.halfDays}`;
      },
    },
    {
      data: null,
      title: "반반휴강",
      render: function (data, type, row) {
        return `${data.quarterUseDays} / ${data.quarterDays}`;
      },
    },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "휴강 승인",
      render: function (data, type, row) {
        if (data.leaveLogList.length === 0) {
          return '';
        }
        const getInstructor = userMeData.instructor
        if (getInstructor.position === 'BASIC') return ''; 
        
        return ReactDOMServer.renderToString( // JSX를 문자열로 변환
          <div className="flex align-items-center list-user-action">
            <Fragment>
              <ListButton
                href={`/manage/leave-request/${data.instructorId}`}
                tooltipTitle="Edit"
                ariaLabel="Edit"
                className="btn-warning"
              >
                <EditIcon />
              </ListButton>
            </Fragment>
          </div>
        );
      },
    },
  ], [userMeData]); // userMeData가 변경될 때 columns 재생성

  const listtableRef = useRef(null);
  useDataTable({
    tableRef: listtableRef,
    columns: columns,
    data: leaveLists,
  });

  useEffect(() => {
    if (listtableRef.current && leaveLists.length > 0) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
  
      $("#datatable-ecom2").DataTable({
        data: leaveLists,
        columns: columns,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [leaveLists, columns]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">휴강 현황</h4>
              </div>
              <Form.Select
                value={year}
                onChange={handleYearChange}
                className="year-selector"
                style={{ width: "150px" }}
              >
                {Array.from({ length: 2 }, (_, i) => currentYear - i).map((y) => (
                  <option key={y} value={y}>
                    {y}년
                  </option>
                ))}
              </Form.Select>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom2"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

LeaveList.displayName = "LeaveList";
export default LeaveList;
