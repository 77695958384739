import { format, parseISO } from "date-fns";

/**
 * Formats a date string in ISO format to 'YYYY-MM-DD'.
 * @param {string} isoString - The ISO string to format.
 * @return {string} The formatted date string.
 */
export const formatDate = (isoString) => {
  return format(parseISO(isoString), "yyyy-MM-dd");
};

export const formatDate2 = (datetimeString) => {
  const date = new Date(datetimeString);
  if (isNaN(date.getTime())) {
    // Invalid date
    return "Invalid date";
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
