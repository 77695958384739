import { useState, useEffect } from 'react';
import { getConfigLessonList } from '../../api/config';

export const useLessonData = (storeId) => {
  const [lessonTypes, setLessonTypes] = useState([]);
  const [lessonOptions, setLessonOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        setLoading(true);
        const configData = await getConfigLessonList(storeId);

        const types = configData.reduce((acc, item) => {
          if (!acc.some((type) => type.lessonType === item.lessonType)) {
            acc.push({ lessonType: item.lessonType, lessonTypeName: item.lessonTypeName });
          }
          return acc;
        }, []);

        const options = [
          ...new Set(
            configData
              .map((item) => ({
                lessonOption: item.lessonOption,
                lessonType: item.lessonType,
                lessonTypeName: item.lessonTypeName,
              }))
              .filter((option) => option.lessonOption !== null && option.lessonOption !== "")
          ),
        ];

        setLessonTypes(types);
        setLessonOptions(options);
      } catch (err) {
        console.error("Failed to fetch getConfigLessonList", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (storeId) {
      fetchConfigData();
    }
  }, [storeId]);

  return { lessonTypes, lessonOptions, loading, error };
};
