import { useState, useEffect } from 'react';
import { getUserMe } from '../api/auth';

const useUserMe = () => {
  const [userMeData, setUserMeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userData = await getUserMe();
        setUserMeData(userData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, []);

  return { userMeData, loading, error };
};

export default useUserMe;