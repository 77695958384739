import { useState, useEffect, useCallback } from "react";
import { getUserInfo } from "../api/auth";

const useUserInfo = (userId) => {
  const [userInfoData, setUserInfoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (userId) {
        const data = await getUserInfo(userId);
        setUserInfoData(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { userInfoData, refresh: fetchData };
};

export default useUserInfo;
