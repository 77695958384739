import { useState, useEffect } from 'react';
import { getUserLessonInfo } from '../api/lesson';

const useUserLessonInfo = (userId) => {
  const [userLessonInfo, setUserLessonInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserLessonInfo(userId);
        setUserLessonInfo(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return { userLessonInfo, loading, error };
};

export default useUserLessonInfo;
