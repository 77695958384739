// utils/dateUtils.js
export const dateSearch = (dateRange, callback) => {
  if (dateRange.length === 2) {
    const startDate = `${dateRange[0].getFullYear()}-${(dateRange[0].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[0].getDate().toString().padStart(2, '0')}`;
    const endDate = `${dateRange[1].getFullYear()}-${(dateRange[1].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[1].getDate().toString().padStart(2, '0')}`;

    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);

    if (callback) {
      callback(startDate, endDate);
    }
  } else {
    alert('날짜 범위를 선택해주세요.');
  }
};

export const dateTypeSearch = (dateRange, dateType, dateValue, callback) => {
  let startDate, endDate;
  // dateRange의 길이가 2인 경우에만 처리 (시작 날짜와 종료 날짜가 모두 선택된 경우)
  if (dateType === '일') {
    // '일' 단위: 현재 방식 그대로 사용 (시작일과 종료일을 그대로 반환)
    if (dateRange.length !== 2) {
      alert('날짜 범위를 선택해주세요.');
      return false;
    }
    startDate = `${dateRange[0].getFullYear()}-${(dateRange[0].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[0].getDate().toString().padStart(2, '0')}`;
    endDate = `${dateRange[1].getFullYear()}-${(dateRange[1].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[1].getDate().toString().padStart(2, '0')}`;
  } else if (dateType === '월') {
    // '월' 단위: 선택한 달의 첫째 날과 마지막 날로 변환
    console.log(dateRange);
    if (dateRange.length === 0) {
      dateRange = [new Date()]
    }
    const startYear = dateRange[0].getFullYear();
    const startMonth = (dateRange[0].getMonth() + 1).toString().padStart(2, '0');
    const endYear = dateRange[0].getFullYear();
    const endMonth = (dateRange[0].getMonth() + 1).toString().padStart(2, '0');

    // 시작 월의 첫 번째 날과 종료 월의 마지막 날 계산
    startDate = `${startYear}-${startMonth}-01`;
    endDate = `${endYear}-${endMonth}-${new Date(endYear, dateRange[0].getMonth() + 1, 0).getDate()}`; // 마지막 날 계산
  } else if (dateType === '년') {
    // '년' 단위: 선택한 해의 첫째 날과 마지막 날로 변환
    const startYear = dateValue;
    const endYear = dateValue;

    startDate = `${startYear}-01-01`; // 해당 년도의 첫째 날
    endDate = `${endYear}-12-31`;     // 해당 년도의 마지막 날
  } else {
    alert('잘못된 날짜 유형입니다. "일", "월", 또는 "년"을 선택하세요.');
  }

  // 콘솔에 결과 출력 (디버깅용)
  console.log('시작 날짜:', startDate);
  console.log('종료 날짜:', endDate);

  // callback 함수가 있는 경우, 시작 날짜와 종료 날짜를 인자로 전달
  if (callback) {
    callback(startDate, endDate);
  }
};

export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

export const getTodayMonthDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based

  let startDate = `${year}-${month}-01`;
  let endDate = `${year}-${month}-${new Date(year, today.getMonth() + 1, 0).getDate()}`; // 마지막 날 계산

  return { startDate, endDate, today }
}