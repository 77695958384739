import { useState, useEffect } from 'react';
import { getUserProgramLogs } from '../api/auth';

const useUserProgramLogs = (userId) => {
  const [userProgramLogs, setUserLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const data = await getUserProgramLogs(userId);
          setUserLists(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return { userProgramLogs, loading, error };
};

export default useUserProgramLogs;
