import { useState, useEffect } from 'react';
import { getInstructorLists } from '../api/instructor';

const useFetchInstructorList = (storeId) => {
  const [instructorListOption, setInstructorListOption] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInstructorList = async () => {
      try {
        const instructorList = await getInstructorLists(storeId);
        const transformedOptions = instructorList.map(instructor => ({
          value: instructor.id,
          label: instructor.user.name,
        }));
        setInstructorListOption(transformedOptions);
      } catch (err) {
        setError(err);
      }
    };
    fetchInstructorList();
  }, [storeId]);

  return { instructorListOption, error };
};

export default useFetchInstructorList;
