import React, { memo, Fragment, useRef, useEffect } from "react";

//react-bootstrap
import { Row, Col, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
import exportToExcel from "../../../utilities/exportToExcel";
import useSalesAnalysisList from "../../../hooks/sales/useSalesAnalysisList";

const SalesAnalysis = memo(() => {
  const { salesAnalysisData, loading, error } = useSalesAnalysisList();

  const columns = useRef([
    {
      data: null,
      title: "번호",
      render: function (data, type, row, meta) {
        return meta.row + 1;
      },
    },
    { data: "storeName", title: "지점명" },
    { data: "classificationCount.INDIVIDUAL", title: "개인" },
    { data: "classificationCount.GROUP", title: "그룹" },
    { data: "classificationCount.MIX", title: "믹스" },
    { data: "classificationCount.LEADER", title: "지도자" },
    { data: "classificationCount.OUTSIDE", title: "외부" },
    { data: "classificationCount.ETC", title: "기타" },
  ]);

  const listtableRef = useRef(null);

  useEffect(() => {
    if (listtableRef.current && salesAnalysisData) {
      if ($.fn.DataTable.isDataTable("#datatable-ecom2")) {
        $("#datatable-ecom2").DataTable().destroy();
      }
      $("#datatable-ecom2").DataTable({
        data: salesAnalysisData,
        columns: columns.current,
        createdRow: function (row, data, dataIndex) {
          $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
      });
    }
  }, [salesAnalysisData]);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: salesAnalysisData,
  });

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">지점별 매출현황</h4>
              </div>
              <div>
                <Button type="button" variant="btn btn-success" onClick={() => exportToExcel(salesAnalysisData, 'file')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>{" "}
                  엑셀 다운로드
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

SalesAnalysis.displayName = "SalesAnalysis";
export default SalesAnalysis;
