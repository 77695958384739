import apiClient from "./axiosConfig";

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getLessonList = async (storeId, searchDate, instructorId, isGroup) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/lesson/lists`, {
      params: { storeId, searchDate, instructorId, isGroup },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching: ", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch");
  }
};

// 오픈된 레슨만 보여줘야 한다.
export const getLessonUserList = async (storeId, searchDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/lesson/lists`, {
      params: { storeId, searchDate },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching: ", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch");
  }
};

export const getLessonReservationList = async (userId, startDate, endDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/lesson/${userId}/lists`, {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching: ", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to getLessonReservationList");
  }
}

export const createLesson = async (lessonData) => {
  try {
    const response = await apiClient.post(`${API_URL}/admin/lesson/create`, lessonData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "createLesson failed");
  }
};

export const updateLesson = async (lessonData) => {
  try {
    const response = await apiClient.put(`${API_URL}/admin/lesson/update`, lessonData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "updateLesson failed");
  }
};

export const deleteLesson = async (lessonId) => {
  try {
    const response = await apiClient.delete(`${API_URL}/admin/lesson/${lessonId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "deleteLesson failed");
  }
};

export const getLesson = async (lessonId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/lesson/${lessonId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getLesson failed");
  }
};

export const getLessonUser = async (lessonId) => {
  try {
    const response = await apiClient.get(`${API_URL}/lesson/${lessonId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getLessonUser failed");
  }
};

export const getUserLessonInfo = async (userId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/lesson/${userId}/info`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getUserLessonInfo failed");
  }
};

export const reservationLesson = async (userId, lessonId) => {
  try {
    const response = await apiClient.post(`${API_URL}/lesson/${userId}/reservation/${lessonId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "reservationLesson failed");
  }
};

export const waitingLesson = async (userId, lessonId) => {
  try {
    const response = await apiClient.post(`${API_URL}/lesson/${userId}/waiting/${lessonId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "waitingLesson failed");
  }
}

export const cancelLesson = async (userId, lessonId) => {
  try {
    const response = await apiClient.delete(`${API_URL}/lesson/${userId}/cancel/${lessonId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "cancelLesson failed");
  }
};

export const getLessonsForCurrentWeek = async (storeId) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/lesson/week`, {
      params: { storeId },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "getUserLessonInfo failed");
  }
}

export const getInstructorStatistics = async (startDate, endDate) => {
  try {
    const response = await apiClient.get(`${API_URL}/admin/lesson/store-statistics`, {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching getInstructorStatistics:", error.response?.data || error.message); // Debugging log
    throw new Error(error.response?.data?.message || "Failed to fetch getInstructorStatistics");
  }
}